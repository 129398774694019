import qs from 'qs';

import {
  AcceptedInvoiceResponse,
  InboundHoaInvoice,
} from '~/common/types/finance/inbound/hoa/inboundHoaInvoices';
import {
  CopySpreadsheetLineItemsRequest,
  UpdateFinanceRemarkRequest,
} from '~/common/types/finance/financeRequests';
import {
  CreateDraftInboundHoaInvoiceRequest,
  CreateEnvelopeRequest,
  CreateInboundHoaInvoiceRequest,
  FilteredAcceptedInvoicesPagedRequest,
  FilteredInvoicesPagedRequest,
  UpdateAcceptedInvoicePaymentSettingsRequest,
} from '~/common/types/finance/inbound/hoa/inboundHoaInvoiceRequests';
import {
  CreateHoaBookingRuleRequest,
  DeleteHoaBookingRuleRequest,
  HoaBookingRule,
  UpdateHoaBookingRuleRequest,
} from '~/common/types/homeownerAssociation/homeownerAssociationBookingRule';
import {
  CreateRepairSalesInvoicePaymentRequest,
  CreateRepairSalesInvoiceRequest,
  LinkRepairPaymentToRepairPaymentRequestRequest,
  SetRepairPaymentRequestOnHoldRequest,
  UpdateRepairPaymentRequestFlowStatusRequest,
} from '~/common/types/finance/outbound/repair/repairSalesInvoiceRequests';
import { PaymentSettings, PurchaseInvoiceSettings } from '~/common/types/finance';
import {
  RepairSalesInvoice,
  RepairSalesInvoicePaymentRequestStatus,
} from '~/common/types/finance/outbound/repair/repairSalesInvoices';
import { DebtorSettings } from '~/common/types/finance/debtorSettings';
import { File } from '@/types/documents';
import { HomeOwnerAssociationFinancialSettings } from '~/common/types/homeownerAssociation/homeownerAssociationFinancialSettings';
import { PagedResult } from '~/common/types';
import { ProvisionSettingsDeleteRequest } from '~/common/types/finance/provisionSettings/provisionSettingsDeleteRequest';
import { ProvisionSettingsFormFields } from '~/common/types/finance/provisionSettings/useProvisionSettingsValidationSchema';
import { ProvisionSettingsType } from '~/common/types/finance/provisionSettings/provisionSettings';
import { ProvisionSettingsVariables } from '~/common/types/finance/provisionSettings/provisionSettingsVariables';
import { Spreadsheet } from '@/types/finance';
import { Task } from '~/common/types/task/task';
import { UpdateDebtorSettingsRequest } from '~/common/types/finance/debtorSettingRequests';
import { UpdateScanStatusRequest } from '~/common/types/finance/scanRequests';

import config from '@/config/config';
import createRestClient from '../restClient';
import { objectToFormData } from '@/shared/utils/helpers';

const restClient = createRestClient();
const client = createRestClient(config.baseURL);

const bookInvoiceInExactOnline = (invoiceId: string) =>
  restClient.put(`finance/book-invoice?invoiceId=${invoiceId}`);

const copySpreadsheetLineItems = (data: CopySpreadsheetLineItemsRequest) =>
  client.post('/finance/copy-line-items', data);

const createManualCorrection = (data: unknown) =>
  restClient.post('finance/manual-correction', data);

const createManualPayment = (data: unknown) => restClient.post('finance/payment', data);

const deletePayment = (paymentId: string) => restClient.delete(`finance/payment/${paymentId}`);

const deleteSpreadsheet = (spreadsheetId: string) =>
  restClient.delete(`finance/spreadsheet/${spreadsheetId}`);

const getCaseGroupBalance = (caseGroupId: string) =>
  restClient.get(`finance/${caseGroupId}/balance`);

const getManualCorrections = (caseGroupId: string) =>
  restClient.get(`finance/manual-corrections?caseGroupId=${caseGroupId}`);

const getSalesInvoiceFile = (salesInvoiceId: string) =>
  restClient.get(`finance/${salesInvoiceId}/sales-invoice.file`);

const getSpreadsheet: (spreadsheetId: string) => Promise<Spreadsheet> = (spreadsheetId) =>
  restClient.get(`finance/${spreadsheetId}`);

const getSpreadsheetFiles = (spreadsheetId: string) =>
  restClient.get(`finance/${spreadsheetId}/file.list`);

const getSpreadsheetsByCaseGroup: (caseGroupId: string) => Promise<Spreadsheet[]> = (caseGroupId) =>
  restClient.get(`finance/list?caseGroupId=${caseGroupId}`);

const linkPaymentToPaymentRequest = (data: LinkRepairPaymentToRepairPaymentRequestRequest) =>
  client.post('/finance/payment/link', data);

const markPaymentRequestAsPaid = (paymentRequestId: string) =>
  restClient.put(`finance/paymentrequest/${paymentRequestId}/mark-as-paid`);

const moveSpreadsheetToOtherCase = (data: unknown) =>
  restClient.put('finance/spreadsheet/case-link', data);

const putAcceptEstimate = (data: unknown) => restClient.put('finance/accept-estimate', data);

const putAcceptPurchaseInvoice = (data: unknown) =>
  restClient.put('finance/accept-purchase-invoice', data);

const putDeleteSpreadsheetReceiver = (data: any) => restClient.delete(`finance/receiver`, data);

const putRejectSpreadsheet = (data: unknown) => restClient.put(`finance/reject`, data);

const putSubmitSpreadsheet = (spreadsheetId: string) =>
  restClient.put(`finance/submit/${spreadsheetId}`);

const putUpdateSpreadsheetInfo = (data: unknown) => restClient.put(`finance`, data);

const putUpdateSpreadsheetLineItems = (data: unknown) => restClient.put(`finance/lineitems`, data);

const putUpdateSpreadsheetReceiver = (data: unknown) => restClient.put(`finance/receiver`, data);

const putUpdateSpreadsheetSender = (data: unknown) => restClient.put(`finance/sender`, data);

const switchPaymentRequestDebtorFlow = (paymentRequestId: string) =>
  restClient.put(`finance/debtors/${paymentRequestId}/flow`);

const updateSpreadsheetPublicity = (data: unknown) => restClient.put('finance/public', data);

const updateSpreadsheetRemark = (data: UpdateFinanceRemarkRequest) =>
  restClient.put('finance/spreadsheet/remark', data);

// settings

const getDebtorSettings = (): Promise<DebtorSettings> => client.get('/finance/debtor/settings');

const getPaymentSettings = (): Promise<PaymentSettings> => client.get('finance/settings/payment');

const updateDebtorSettings = (data: UpdateDebtorSettingsRequest) =>
  client.put('finance/debtor/settings', data);

const activateReadFromMailbox = (): Promise<PurchaseInvoiceSettings> =>
  client.put('finance/settings/purchase-invoice/activate-reading');

const deActivateReadFromMailbox = (): Promise<PurchaseInvoiceSettings> =>
  client.put('finance/settings/purchase-invoice/deactivate-reading');

const getDocumentLink = (uploadId: string): Promise<string> =>
  client.get(`/finance/scan/document-link/${uploadId}`);

const updateScanStatus = (data: UpdateScanStatusRequest) =>
  client.put('/finance/scan/status', data);

const getRepairSalesInvoices = (caseGroupId: string): Promise<RepairSalesInvoice[]> =>
  client.get(`/finance/outbound/repair/invoice/case-group/${caseGroupId}`);

const getRepairSalesInvoiceFile = (id: string): Promise<File> =>
  client.get(`/finance/outbound/repair/invoice/${id}/file`);

const getOpenRepairSalesInvoicePaymentRequests = (): Promise<
  RepairSalesInvoicePaymentRequestStatus[]
> => client.get('/finance/outbound/repair/invoice/payment-request/open');

const updateRepairSalesInvoiceRemark = (data: UpdateFinanceRemarkRequest) =>
  client.put('/finance/outbound/repair/invoice/remark', data);

const updateRepairPaymentRequestRemark = (data: UpdateFinanceRemarkRequest) =>
  client.put('/finance/outbound/repair/invoice/payment-request/remark', data);

const linkRepairPaymentToRepairPaymentRequest = (
  data: LinkRepairPaymentToRepairPaymentRequestRequest,
) => client.post('/finance/outbound/repair/invoice/payment/link', data);

const createRepairSalesInvoice = (data: CreateRepairSalesInvoiceRequest) =>
  client.post('/finance/outbound/repair/invoice', data);

const deleteRepairSalesInvoice = (id: string) =>
  client.delete(`/finance/outbound/repair/invoice/${id}`);

const createRepairSalesInvoicePayment = (data: CreateRepairSalesInvoicePaymentRequest) =>
  client.post('/finance/outbound/repair/invoice/payment', data);

const deleteRepairSalesInvoicePayment = (id: string) =>
  client.delete(`/finance/outbound/repair/invoice/payment/${id}`);

const markRepairSalesInvoicePaymentRequestAsPaid = (id: string) =>
  client.post(`/finance/outbound/repair/invoice/payment-request/${id}/mark-as-paid`);

const toggleRepairSalesInvoicePaymentRequestFlow = (id: string) =>
  client.post(`/finance/outbound/repair/invoice/payment-request/${id}/toggle-flow`);

const moveRepairSalesInvoicePaymentRequestToDebtCollection = (id: string) =>
  client.post(`/finance/outbound/repair/invoice/payment-request/${id}/move-to-debt-collection`);

const getInboundHoaInvoiceToValidate = (invoiceId: string): Promise<InboundHoaInvoice> =>
  client.get(`/finance/inbound/hoa/invoices-to-validate/${invoiceId}`);

const getInboundHoaInvoiceDocument = (invoiceId: string): Promise<File> =>
  client.get(`/finance/inbound/hoa/${invoiceId}/document`);

const createHomeownerAssociationBookingRule = (data: CreateHoaBookingRuleRequest) =>
  client.post(`/finance/homeownerassociation/${data.homeownerAssociationId}/bookingrules`, data);

// eslint-disable-next-line
const deleteHomeownerAssociationBookingRule = ({
  homeownerAssociationId,
  id,
}: DeleteHoaBookingRuleRequest) =>
  client.delete(`/finance/homeownerassociation/${homeownerAssociationId}/bookingrules`, {
    data: {
      ids: [id],
    },
  });

const getHomeownerAssociationBookingRules = (
  homeownerAssociationId: string,
): Promise<HoaBookingRule[]> =>
  client.get(`/finance/homeownerassociation/${homeownerAssociationId}/bookingrules`);

const updateHomeownerAssociationBookingRule = (data: UpdateHoaBookingRuleRequest) =>
  client.put(
    `/finance/homeownerassociation/${data.homeownerAssociationId}/bookingrules/${data.id}`,
    data,
  );

const createInboundHoaInvoice = (data: CreateInboundHoaInvoiceRequest) =>
  restClient.postForm('/finance/invoicing/inbound/hoa/invoice', objectToFormData(data));

const createDraftInboundHoaInvoice = (data: CreateDraftInboundHoaInvoiceRequest) =>
  restClient.postForm('/finance/invoicing/inbound/hoa/invoice/draft', objectToFormData(data));

const getHomeownerAssociationFinancialSettings = (
  homeownerAssociationId: string,
): Promise<HomeOwnerAssociationFinancialSettings> =>
  client.get(`/finance/homeownerAssociation/${homeownerAssociationId}/financialSettings`);

const updateRepairSalesInvoicePaymentRequestFlowStatus = (
  data: UpdateRepairPaymentRequestFlowStatusRequest,
) => client.put('/finance/outbound/repair/invoice/payment-request/flow-status', data);

const setRepairSalesInvoicePaymentRequestOnHold = (data: SetRepairPaymentRequestOnHoldRequest) =>
  client.put('/finance/outbound/repair/invoice/payment-request/on-hold', data);

const getProvisionSettings = (): Promise<ProvisionSettingsType[]> =>
  client.get('/finance/settings/provision');

const createProvisionSettings = (data: ProvisionSettingsFormFields) =>
  client.post('/finance/settings/provision', data);

const updateProvisionSettings = (data: ProvisionSettingsVariables) =>
  client.put(`/finance/settings/provision/${data.id}`, data);

const deleteProvisionSettings = (data: ProvisionSettingsDeleteRequest) =>
  client.delete('/finance/settings/provision', { data });

const getAcceptedInvoices = (
  request: FilteredAcceptedInvoicesPagedRequest,
): Promise<PagedResult<AcceptedInvoiceResponse>> =>
  client.get('/finance/inbound/hoa/accepted', { params: request });

const updateAcceptedInboundHoaInvoicePaymentSettings = (
  request: UpdateAcceptedInvoicePaymentSettingsRequest,
): Promise<void> => client.put('/finance/inbound/hoa/payment-settings', request);

const getInboundHoaInvoiceTasks = (invoiceId: string): Promise<Task[]> =>
  client.get(`/finance/inbound/hoa/${invoiceId}/tasks`);

const createEnvelope = (data: CreateEnvelopeRequest) =>
  client.post('/finance/inbound/hoa/envelope', data);

const downloadDocumentsForInvoicesAsZip = (invoiceIds: string[]): Promise<Blob> =>
  restClient.get('/finance/invoicing/inbound/hoa/invoice/documents', {
    params: { invoiceIds },
    paramsSerializer: (params) => qs.stringify(params),
    responseType: 'blob',
  });

export default {
  activateReadFromMailbox,
  bookInvoiceInExactOnline,
  copySpreadsheetLineItems,
  createDraftInboundHoaInvoice,
  createEnvelope,
  createHomeownerAssociationBookingRule,
  createInboundHoaInvoice,
  createManualCorrection,
  createManualPayment,
  createProvisionSettings,
  createRepairSalesInvoice,
  createRepairSalesInvoicePayment,
  deActivateReadFromMailbox,
  deleteHomeownerAssociationBookingRule,
  deletePayment,
  deleteProvisionSettings,
  deleteRepairSalesInvoice,
  deleteRepairSalesInvoicePayment,
  deleteSpreadsheet,
  downloadDocumentsForInvoicesAsZip,
  getAcceptedInvoices,
  getCaseGroupBalance,
  getDebtorSettings,
  getDocumentLink,
  getHomeownerAssociationBookingRules,
  getHomeownerAssociationFinancialSettings,
  getInboundHoaInvoiceDocument,
  getInboundHoaInvoiceTasks,
  getInvoiceToValidate: getInboundHoaInvoiceToValidate,
  getManualCorrections,
  getOpenRepairSalesInvoicePaymentRequests,
  getPaymentSettings,
  getProvisionSettings,
  getRepairSalesInvoiceFile,
  getRepairSalesInvoices,
  getSalesInvoiceFile,
  getSpreadsheet,
  getSpreadsheetFiles,
  getSpreadsheetsByCaseGroup,
  linkPaymentToPaymentRequest,
  linkRepairPaymentToRepairPaymentRequest,
  markPaymentRequestAsPaid,
  markRepairSalesInvoicePaymentRequestAsPaid,
  moveRepairSalesInvoicePaymentRequestToDebtCollection,
  moveSpreadsheetToOtherCase,
  putAcceptEstimate,
  putAcceptPurchaseInvoice,
  putDeleteSpreadsheetReceiver,
  putRejectSpreadsheet,
  putSubmitSpreadsheet,
  putUpdateSpreadsheetInfo,
  putUpdateSpreadsheetLineItems,
  putUpdateSpreadsheetReceiver,
  putUpdateSpreadsheetSender,
  setRepairSalesInvoicePaymentRequestOnHold,
  switchPaymentRequestDebtorFlow,
  toggleRepairSalesInvoicePaymentRequestFlow,
  updateAcceptedInboundHoaInvoicePaymentSettings,
  updateDebtorSettings,
  updateHomeownerAssociationBookingRule,
  updateProvisionSettings,
  updateRepairPaymentRequestRemark,
  updateRepairSalesInvoicePaymentRequestFlowStatus,
  updateRepairSalesInvoiceRemark,
  updateScanStatus,
  updateSpreadsheetPublicity,
  updateSpreadsheetRemark,
};
