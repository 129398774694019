enum MessageTemplateContext {
  Case = 'Case',
  CaseComplaint = 'CaseComplaint',
  CaseRepair = 'CaseRepair',
  CaseInsurance = 'CaseInsurance',
  Building = 'Building',
  Finance = 'Finance',
  Meeting = 'Meeting',
  RepairDebtors = 'RepairDebtors',
  Provision = 'Provision',
  HomeownerAssociation = 'HomeownerAssociation',
  Settlement = 'Settlement',
  CombinedCommunication = 'CombinedCommunication',
  HoaDebtors = 'HoaDebtors',
}

export default MessageTemplateContext;
