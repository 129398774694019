import { Input, IconButton as MuiIconButton, Tooltip } from '@mui/joy';
import { memo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useSearchFilter } from '../../hooks/UseFilters';
import useToggle from '../../hooks/UseToggle';

import { ClearIcon, SearchIcon } from '../../icons/Icons';
import IconButton from '../buttons/IconButton';

type Props = {
  className?: string;
  fixed?: boolean;
  placeholder?: string;
  setSearchText: (searchText: string) => void;
};

const DataTableSearch: React.FC<Props> = ({ className, fixed, placeholder, setSearchText }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const touchedRef = useRef(false);

  const { debounceSearch, search: searchString } = useSearchFilter({ startQueryLength: 1 });
  const { t } = useTranslation();

  const searchToggle = useToggle();

  useEffect(() => {
    if (!searchString && !touchedRef.current) return;

    touchedRef.current = true;

    setSearchText(searchString);
  }, [searchString, setSearchText]);

  const handleBlur = () => {
    if (!inputRef.current) return;

    if (!inputRef.current.value) searchToggle.hide();
  };

  const handleClear = () => {
    if (!inputRef.current) return;

    const newValue = '';

    inputRef.current.value = newValue;
    debounceSearch(newValue);
    inputRef.current.focus();
  };

  return (
    <>
      {!searchToggle.value && !fixed && (
        <Tooltip title={t('search.label')}>
          <MuiIconButton
            className={classNames('align-self-center aspect-square', className)}
            onClick={searchToggle.show}
          >
            <SearchIcon />
          </MuiIconButton>
        </Tooltip>
      )}

      {(searchToggle.value || fixed) && (
        <Input
          autoFocus={!fixed}
          className={className}
          placeholder={placeholder || t('search.placeholder')}
          slotProps={{ input: { ref: inputRef } }}
          endDecorator={<IconButton onClick={handleClear} iconComponent={<ClearIcon />} />}
          startDecorator={<SearchIcon />}
          onBlur={handleBlur}
          onChange={(e) => debounceSearch(e.currentTarget.value)}
        />
      )}
    </>
  );
};

export default memo(DataTableSearch);
