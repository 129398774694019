import * as yup from 'yup';
import {
  Alert,
  Autocomplete,
  AutocompleteOption,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  ListItemContent,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Typography,
} from '@mui/joy';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { lowerFirst } from 'lodash';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';
import CaseRelationRole from '@/shared/enums/CaseRelationRole';

import {
  CancelIcon,
  HomeOwnerAssociationIcon,
  InlineSpinner,
  InsuranceEngineerIcon,
  InsuranceIntermediaryIcon,
  InsurerIcon,
  PropertyManagerIcon,
  SaveIcon,
  SpreadsheetIcon,
  WarningIcon,
} from '@/shared/icons/Icons';
import { trpc } from '@/config/trpc';

type Props = {
  title: string;
};

const UploadEstimateModal: React.FC<Props> = ({ title }) => {
  const { ui, setUi } = useContext(AppContext);
  const [navigateTo, setNavigateTo] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { caseId } = useParams() as { caseId: string };
  const { enqueueSnackbar } = useSnackbar();

  const { data: currentCase } = trpc.case.byId.useQuery(caseId);

  const sender = currentCase?.relations.find((relation) => relation.isContractor) || null;
  const receiver = currentCase?.relations.find((relation) => relation.isOwner) || null;

  const relationsWithAutocompleteOptions = currentCase?.relations.map((relation) => ({
    ...relation,
    label: relation.displayName ?? '',
    value: relation.id,
  }));

  const {
    mutateAsync: createSpreadsheetDraftAsync,
    isLoading: createSpreadsheetDraftAsyncIsProcessing,
    error,
  } = trpc.finance.spreadsheet.createSpreadsheetDraft.useMutation();

  const formik = useFormik({
    initialValues: {
      caseId,
      description: null,
      receiver: {
        address: {
          addressLine: receiver?.relationAddressAddressLine,
          city: receiver?.relationAddressCity,
          country: receiver?.relationAddressCountry,
          postalCode: receiver?.relationAddressPostalCode,
        },
        companyName: receiver?.companyName,
        emailAddresses: receiver?.emailAddresses,
        firstName: receiver?.firstName,
        iban: receiver?.iban,
        language: receiver?.language,
        lastName: receiver?.lastName,
        mobilePhoneNumber: receiver?.mobilePhoneNumber,
        phoneNumber: receiver?.phoneNumber,
        teamId: receiver?.teamId,
        vat: receiver?.vat,
      },
      remark: null,
      sender: {
        address: {
          addressLine: sender?.relationAddressAddressLine,
          city: sender?.relationAddressCity,
          country: sender?.relationAddressCountry,
          postalCode: sender?.relationAddressPostalCode,
        },
        companyName: sender?.companyName,
        emailAddresses: sender?.emailAddresses,
        firstName: sender?.firstName,
        iban: sender?.iban,
        language: sender?.language,
        lastName: sender?.lastName,
        mobilePhoneNumber: sender?.mobilePhoneNumber,
        phoneNumber: sender?.phoneNumber,
        teamId: sender?.teamId,
        vat: sender?.vat,
      },
    },
    onSubmit: async (values) => {
      const data = {
        ...values,
      };
      const { data: spreadSheetId } = await createSpreadsheetDraftAsync(data);

      setUi({ type: actions.CASE_TOGGLE_UPLOAD_ESTIMATE });

      enqueueSnackbar(t('typeSuccessfullyAdded', { type: t('spreadsheet') }), {
        variant: 'success',
      });

      if (navigateTo) {
        navigate(`/spreadsheet/${spreadSheetId}`);
      }
    },
    validationSchema: yup.object().shape({
      description: yup.string().required().nullable(),
      remark: yup.string().required().nullable(),
    }),
  });

  const { values, setFieldValue, errors, handleSubmit, touched } = formik;

  return (
    <Modal
      open={ui.case.toggles.uploadEstimate}
      onClose={() => setUi({ type: actions.CASE_TOGGLE_UPLOAD_ESTIMATE })}
    >
      <ModalDialog className="w-2/3">
        <ModalClose />
        <Typography startDecorator={<SpreadsheetIcon />} className="font-medium">
          {title}
        </Typography>
        <Divider />

        {!!error && (
          <Alert startDecorator={<WarningIcon />} color="danger">
            {t('errors:oops')}
          </Alert>
        )}

        <form className="mt-2 flex flex-col space-y-4" onSubmit={handleSubmit}>
          <div className="flex space-x-4">
            <FormControl className="w-1/2">
              <FormLabel required>{t('sender')}</FormLabel>
              <Autocomplete
                value={
                  relationsWithAutocompleteOptions?.find(
                    (r) => values.sender.teamId === r.teamId,
                  ) || null
                }
                className="grow"
                placeholder={t('typeHere')}
                options={relationsWithAutocompleteOptions || []}
                onChange={(_e, relation) => {
                  const selectedSender = currentCase?.relations?.find((r) => r.id === relation?.id);
                  setFieldValue('sender', {
                    address: {
                      addressLine: selectedSender?.relationAddressAddressLine,
                      city: selectedSender?.relationAddressCity,
                      country: selectedSender?.relationAddressCountry,
                      postalCode: selectedSender?.relationAddressPostalCode,
                    },
                    companyName: selectedSender?.companyName,
                    emailAddresses: selectedSender?.emailAddresses,
                    firstName: selectedSender?.firstName,
                    iban: selectedSender?.iban,
                    language: selectedSender?.language,
                    lastName: selectedSender?.lastName,
                    mobilePhoneNumber: selectedSender?.mobilePhoneNumber,
                    phoneNumber: selectedSender?.phoneNumber,
                    teamId: selectedSender?.teamId,
                    vat: selectedSender?.vat,
                  });
                }}
                renderOption={(props, option) => (
                  <AutocompleteOption {...props}>
                    <ListItemContent>
                      <Typography
                        startDecorator={
                          <>
                            {option.isOwner && <Chip variant="soft">{t('owner')}</Chip>}
                            {option.isRequestor && <Chip variant="soft">{t('requestor')}</Chip>}
                            {option.isClient && <Chip variant="soft">{t('client')}</Chip>}
                            {option.isContractor && (
                              <Chip variant="soft">{t('_contractor.title')}</Chip>
                            )}
                          </>
                        }
                        endDecorator={
                          <>
                            {option.role === CaseRelationRole.Insurer && <InsurerIcon />}
                            {option.role === CaseRelationRole.InsuranceEngineer && (
                              <InsuranceEngineerIcon />
                            )}
                            {option.role === CaseRelationRole.InsuranceIntermediary && (
                              <InsuranceIntermediaryIcon />
                            )}
                            {option.role === CaseRelationRole.HomeOwnerAssociation && (
                              <HomeOwnerAssociationIcon />
                            )}
                            {option.role === CaseRelationRole.PropertyManager && (
                              <PropertyManagerIcon />
                            )}
                          </>
                        }
                      >
                        {option.displayName}
                      </Typography>
                    </ListItemContent>
                  </AutocompleteOption>
                )}
              />
            </FormControl>

            <FormControl className="w-1/2">
              <FormLabel required>{t('receiver')}</FormLabel>
              <Autocomplete
                value={
                  relationsWithAutocompleteOptions?.find(
                    (r) => values.receiver.teamId === r.teamId,
                  ) || null
                }
                className="grow"
                placeholder={t('typeHere')}
                options={relationsWithAutocompleteOptions || []}
                onChange={(_e, option) => {
                  setFieldValue('receiver', {
                    address: {
                      addressLine: option?.relationAddressAddressLine,
                      city: option?.relationAddressCity,
                      country: option?.relationAddressCountry,
                      postalCode: option?.relationAddressPostalCode,
                    },
                    companyName: option?.companyName,
                    emailAddresses: option?.emailAddresses,
                    firstName: option?.firstName,
                    iban: option?.iban,
                    language: option?.language,
                    lastName: option?.lastName,
                    mobilePhoneNumber: option?.mobilePhoneNumber,
                    phoneNumber: option?.phoneNumber,
                    teamId: option?.teamId,
                    vat: option?.vat,
                  });
                }}
                renderOption={(props, option) => (
                  <AutocompleteOption {...props}>
                    <ListItemContent>
                      <Typography
                        startDecorator={
                          <>
                            {option.isOwner && <Chip variant="soft">{t('owner')}</Chip>}
                            {option.isRequestor && <Chip variant="soft">{t('requestor')}</Chip>}
                            {option.isClient && <Chip variant="soft">{t('client')}</Chip>}
                            {option.isContractor && (
                              <Chip variant="soft">{t('_contractor.title')}</Chip>
                            )}
                          </>
                        }
                        endDecorator={
                          <>
                            {option.role === CaseRelationRole.Insurer && <InsurerIcon />}
                            {option.role === CaseRelationRole.InsuranceEngineer && (
                              <InsuranceEngineerIcon />
                            )}
                            {option.role === CaseRelationRole.InsuranceIntermediary && (
                              <InsuranceIntermediaryIcon />
                            )}
                            {option.role === CaseRelationRole.HomeOwnerAssociation && (
                              <HomeOwnerAssociationIcon />
                            )}
                            {option.role === CaseRelationRole.PropertyManager && (
                              <PropertyManagerIcon />
                            )}
                          </>
                        }
                      >
                        {option.displayName}
                      </Typography>
                    </ListItemContent>
                  </AutocompleteOption>
                )}
              />
            </FormControl>
          </div>

          <FormControl error={!!errors.description && !!touched.description}>
            <FormLabel required>{t('description')}</FormLabel>
            <Textarea
              placeholder={t('typeHere')}
              onChange={(e) => setFieldValue('description', e.target.value)}
            />
          </FormControl>
          <FormControl error={!!errors.remark && !!touched.remark}>
            <FormLabel required>{t('remark')}</FormLabel>
            <Textarea
              placeholder={t('typeHere')}
              onChange={(e) => setFieldValue('remark', e.target.value)}
            />
          </FormControl>

          <div className="flex items-center justify-end space-x-4">
            <Checkbox
              label={t('navigateToType', { type: lowerFirst(t('draft')) })}
              checked={navigateTo}
              onChange={() => setNavigateTo(!navigateTo)}
            />

            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<CancelIcon />}
              onClick={() => setUi({ type: actions.CASE_TOGGLE_UPLOAD_ESTIMATE })}
            >
              {t('cancel')}
            </Button>
            <Button
              disabled={createSpreadsheetDraftAsyncIsProcessing}
              type="submit"
              startDecorator={
                createSpreadsheetDraftAsyncIsProcessing ? <InlineSpinner /> : <SaveIcon />
              }
            >
              {t('save')}
            </Button>
          </div>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default UploadEstimateModal;
