import { IconButton, Tooltip } from '@mui/joy';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Task } from '~/common/types/task/task';

import { AppContext } from '@/shared/context/context';
import { distinct } from '@/shared/utils/helpers';
import { useTaskActions } from '@/mutations';

import { SnoozeIcon } from '@/shared/icons/Icons';

type Props = {
  task: Task;
};

const SnoozeTaskButton: React.FC<Props> = ({ task }) => {
  const { snoozeTask, isSnoozingTask } = useTaskActions();
  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation();

  const isFinished = !!task.completedBy?.length || !!task.completedDate;

  const canEditTask = useMemo(() => {
    if (!currentTeam) return false;

    const taskExecutors = task.taskUsers.filter((tu) => tu.isAssigned);
    const executorTeams = taskExecutors.map((te) => te.teamId).filter(distinct);

    return executorTeams.includes(currentTeam.id);
  }, [currentTeam, task]);

  return (
    <Tooltip arrow title={isFinished ? t('cannotChangeFinishedTask') : t('snooze')}>
      <IconButton
        disabled={isFinished || !canEditTask || isSnoozingTask}
        variant="plain"
        onClick={() => snoozeTask(task.id)}
      >
        <SnoozeIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SnoozeTaskButton;
