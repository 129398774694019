import { Alert, Checkbox, IconButton } from '@mui/joy';
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CaseEntityPropTypes from '@/shared/prop-types/CaseEntityPropTypes';
import CasePropTypes from '@/shared/prop-types/CasePropTypes';
import EntityType from '@/shared/enums/EntityType';
import { formDataToApiData } from '@/shared/utils/Calendar/appointments';
import { sentencize } from '@/shared/utils/helpers';
import useAddAppointmentMutation from '../../../../../mutations/case/useAddAppointmentMutation';
import useDayjs from '@/shared/hooks/useDayjs';
import useToggle from '@/shared/hooks/UseToggle';
import useUpdateAppointmentMutation from '../../../../../mutations/case/useUpdateAppointmentMutation';

import { AddIcon, InfoIcon } from '@/shared/icons/Icons';
import AddEditAppointmentModal from '../../../../Calendars/Components/AddEditAppointment/AddEditAppointmentModal';
import AppointmentItem from './AppointmentItem';
import Tooltip from '@/shared/components/2.0/Tooltip';

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  caseEntities: PropTypes.arrayOf(CaseEntityPropTypes).isRequired,
  currentCase: CasePropTypes.isRequired,
};

const CaseAppointments = ({ currentCase, caseEntities, canEdit }) => {
  const { t } = useTranslation();
  const { dayjs, isFutureDate } = useDayjs();

  const [initialAppointment, setInitialAppointment] = useState();

  const showFutureAppointmentsToggle = useToggle(true);
  const appointmentModalToggle = useToggle();

  const { addAppointment, isAddingAppointment } = useAddAppointmentMutation({
    callback: appointmentModalToggle.hide,
  });

  const { updateAppointment, isUpdatingAppointment } = useUpdateAppointmentMutation({
    callback: appointmentModalToggle.hide,
  });

  useEffect(() => {
    if (appointmentModalToggle.value) return;

    setInitialAppointment();
  }, [appointmentModalToggle]);

  const appointments = useMemo(() => {
    if (caseEntities == null || !caseEntities.length) return [];
    const sortedAppointmentFromEntities = caseEntities
      .filter((ent) => ent.type === EntityType.Appointment)
      .sort((a, b) => dayjs(a.startTime).diff(dayjs(b.startTime)));

    if (showFutureAppointmentsToggle.value) {
      return sortedAppointmentFromEntities.filter((a) => isFutureDate(a.startTime));
    }
    return sortedAppointmentFromEntities;
  }, [caseEntities, dayjs, isFutureDate, showFutureAppointmentsToggle]);

  const addEditAppointment = (formData) => {
    if (isUpdatingAppointment || isAddingAppointment) {
      return;
    }

    if (initialAppointment) {
      updateAppointment(formDataToApiData({ appointmentId: initialAppointment.id, ...formData }));
      return;
    }

    addAppointment(formDataToApiData(formData));
  };

  return (
    <div role="presentation" className="relative flex min-h-[400px] w-full flex-col">
      <div className="mb-4 flex justify-between space-x-4 rounded-lg bg-gray-100 p-4">
        <Checkbox
          className="items-center"
          label={t('upcomingAppointments')}
          onChange={(e) => showFutureAppointmentsToggle.setValue(e.target.checked)}
          checked={showFutureAppointmentsToggle.value}
        />

        {canEdit && (
          <Tooltip title={sentencize(t('addType', { type: t('appointment') }))}>
            <IconButton variant="outlined" onClick={appointmentModalToggle.show}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <div className="flex min-h-0 w-full grow-0 flex-col overflow-y-auto overflow-x-hidden overscroll-contain">
        {!showFutureAppointmentsToggle.value && !!appointments.length && (
          <Alert className="mb-4" startDecorator={<InfoIcon />} variant="soft" color="warning">
            {t('allAppointments')}
          </Alert>
        )}

        {appointments.length ? (
          <>
            {appointments.map((a) => (
              <AppointmentItem
                appointment={a}
                currentCase={currentCase}
                key={a.id}
                canEdit={canEdit}
                onEdit={() => {
                  setInitialAppointment(a);
                  appointmentModalToggle.show();
                }}
              />
            ))}
          </>
        ) : (
          <Alert startDecorator={<InfoIcon />} color="success">
            {t('noAppointments')}
          </Alert>
        )}
      </div>
      {canEdit && (
        <AddEditAppointmentModal
          toggle={appointmentModalToggle}
          currentCase={currentCase}
          onSubmit={addEditAppointment}
          isMutating={isAddingAppointment || isUpdatingAppointment}
          initialAppointment={initialAppointment}
        />
      )}
    </div>
  );
};

CaseAppointments.propTypes = propTypes;

export default CaseAppointments;
