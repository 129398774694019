import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TaskOverviewCellProps } from '../config/tasks.table';

import { getLanguageFromCode } from '@/shared/utils/helpers';
import Routes from '../../App/Router/Routes';

const LinkCustomCell: React.FC<TaskOverviewCellProps> = ({ rowData }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { caseTaskInfo, meetingTaskInfo } = rowData;

  if (caseTaskInfo) {
    const { caseReference, caseId } = caseTaskInfo;

    if (caseId && caseReference) {
      return <Link to={`${Routes.CASE_DETAIL_NAVIGATE}/${caseId}`}>{caseReference}</Link>;
    }
  }

  if (meetingTaskInfo) {
    const { meetingId, meetingDescriptions } = meetingTaskInfo;
    if (meetingId && meetingDescriptions) {
      const description = meetingDescriptions.find(
        (tr) => tr.language === getLanguageFromCode(language),
      )?.description;
      return <Link to={`${Routes.MEETING_DETAIL_NAVIGATE}/${meetingId}`}>{description}</Link>;
    }
  }

  return '-';
};

export default LinkCustomCell;
