import { useMemo } from 'react';

import { Enum, UseAutocompleteEnumArgs, UseAutocompleteEnumRes } from './autocomplete.types';

import { generateOptionsFromEnum } from './autocomplete.utils';
import useAutocompleteOptions from './useAutocompleteOptions';

const useAutocompleteEnum = <
  TEnum extends Enum,
  TMultiple extends boolean | undefined = undefined,
  TDisableClearable extends boolean | undefined = undefined,
>({
  includeValue,
  srcEnum,
  translationKey,
  ...args
}: UseAutocompleteEnumArgs<TEnum, TMultiple, TDisableClearable>): UseAutocompleteEnumRes<
  TEnum,
  TMultiple
> => {
  const options = useMemo(
    () => generateOptionsFromEnum({ includeValue, srcEnum, translationKey }),
    [includeValue, srcEnum, translationKey],
  );

  return useAutocompleteOptions({
    data: options,
    ...args,
  });
};

export default useAutocompleteEnum;
