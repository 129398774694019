import { useCallback, useEffect, useMemo } from 'react';

import {
  AutocompleteValue,
  ExtractOption,
  OnChangeEvent,
  OptionsInput,
  UseAutocompleteOptionsArgs,
  UseAutocompleteOptionsRes,
} from './autocomplete.types';

type BaseOptions<TOptions extends OptionsInput | undefined = undefined> = {
  data: TOptions;
  isLoading?: boolean;
};

type Options<
  TOptions extends OptionsInput,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
> = BaseOptions<TOptions> & UseAutocompleteOptionsArgs<TOptions, TMultiple, TDisableClearable>;

const useAutocompleteOptions = <
  TOptions extends OptionsInput,
  TMultiple extends boolean | undefined = undefined,
  TDisableClearable extends boolean | undefined = undefined,
>({
  callback,
  data,
  disableClearable,
  disableCloseOnSelect = false,
  disabled = false,
  enforceSingleOptionPrefill = true,
  flexGrow = false,
  isLoading = false,
  multiple,
  selected,
}: Options<TOptions, TMultiple, TDisableClearable>): UseAutocompleteOptionsRes<
  TOptions,
  TMultiple
> => {
  type Value = AutocompleteValue<ExtractOption<TOptions>, TMultiple, TDisableClearable>;

  const value = useMemo(() => {
    if (!selected) return multiple ? [] : null;

    if (Array.isArray(selected)) {
      return data?.filter((option) => selected?.includes(option.value)) || [];
    }

    return data?.find((option) => option.value === selected) || null;
  }, [data, multiple, selected]);

  const handleChange = useCallback(
    (_: OnChangeEvent, newValue: Value) => {
      const validMulti = !!multiple && Array.isArray(newValue);
      const validSingle = !multiple && (!disableClearable || !!newValue);

      if (!validMulti && !validSingle) return;

      callback(newValue);
    },
    [callback, disableClearable, multiple],
  );

  useEffect(() => {
    const hasValue = !!value && (!Array.isArray(value) || !!value.length);

    if (!enforceSingleOptionPrefill || hasValue || data?.length !== 1) return;

    const newValue = (multiple ? data : data[0]) as Value;

    callback(newValue);
  }, [callback, data, enforceSingleOptionPrefill, multiple, value]);

  return {
    className: flexGrow ? 'min-w-0 flex-1' : undefined,
    disableClearable: !!disableClearable && value !== null,
    disableCloseOnSelect: multiple || disableCloseOnSelect,
    disabled: isLoading || (enforceSingleOptionPrefill && data?.length === 1) || disabled,
    loading: isLoading,
    multiple: (multiple ?? undefined) as TMultiple,
    onChange: handleChange,
    options: (data ?? []) as ExtractOption<TOptions>[],
    value: value as Value,
  };
};

export default useAutocompleteOptions;
