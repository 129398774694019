import { Autocomplete, TextField } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { Grid } from '@mui/joy';
import { Link } from 'react-router-dom';
import { lowerFirst } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  AccessibilityIcon,
  CopyIcon,
  EditIcon,
  InvalidIcon,
  ValidIcon,
} from '@/shared/icons/Icons';
import {
  getCurrentTranslation,
  getDamageTypeTranslation,
  getMomentDescription,
} from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import CaseStatusType from '@/shared/enums/CaseStatusType';
import CaseType from '@/shared/enums/CaseType';
import useToggle from '@/shared/hooks/UseToggle';

import formatContractorSkillDefinition from '../../util';
import { trpc } from '@/config/trpc';
import useUpdateCaseRelationClassification from '@/mutations/case/useUpdateCaseRelationClassification';

import { CaseContext } from '../../CaseContext';
import EditMandateModal from './Components/EditMandateModal';
import IconButton from '@/shared/components/buttons/IconButton';
import Tooltip from '@/shared/components/2.0/Tooltip';

const { VITE_CLIENT_PAGE_URI } = import.meta.env;

const CaseNotificationCardDetails = ({ caseClassifications }) => {
  const { currentTeam } = useContext(AppContext);
  const { currentCase, canEditCase, refetchCase } = useContext(CaseContext);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isRepairCase = currentCase.caseType === CaseType.Repair;
  const isClaim = currentCase.caseType === CaseType.InsuranceClaim;
  const isComplaint = currentCase.caseType === CaseType.Complaint;

  const { updateCaseRelationClassification } = useUpdateCaseRelationClassification({
    callback: refetchCase,
  });
  const { data: linkedCaseData } = trpc.case.byId.useQuery(currentCase.linkedComplaintCaseId, {
    enabled: isComplaint && !!currentCase.linkedComplaintCaseId,
  });

  const mandateToggle = useToggle();

  const [isEditingClassification, setIsEditingClassification] = useState(false);
  const [selectedClassification, updateSelectedClassification] = useState(
    currentCase.classification?.id || null,
  );

  const ownRelation = useMemo(() => {
    if (!currentTeam || !currentCase.id) return null;

    return currentCase.relations.find((relation) => relation.teamId === currentTeam.id);
  }, [currentCase, currentTeam]);

  const contractor = useMemo(
    () => currentCase.relations.find((relation) => relation.isContractor),
    [currentCase.relations],
  );

  const hasClients = useMemo(
    () => currentCase.relations.some((relation) => relation.isClient),
    [currentCase.relations],
  );

  const onBlurClassification = () => {
    updateSelectedClassification(currentCase.classification?.id);
    setIsEditingClassification(false);
  };

  const onChangeClassification = (value) => {
    updateSelectedClassification(value?.id);
    updateCaseRelationClassification({
      caseId: currentCase.id,
      classification: value?.id,
    });
  };

  const isCancelled = currentCase.status.statusType === CaseStatusType.Cancelled;
  const clientPageLink = `${VITE_CLIENT_PAGE_URI}/${language.slice(0, 2)}?caseId=${currentCase.id}`;
  const incidentLabel = isRepairCase || isClaim ? t('incidentDate') : t('notificationDate');

  const showContract =
    (!!currentCase.contractDescription || !!linkedCaseData?.contractDescription) &&
    (isRepairCase || isComplaint);

  return (
    <>
      {showContract && (
        <Grid container>
          <Grid md={4}>{t('contract')}:</Grid>
          <Grid md={8}>
            {currentCase.contractDescription || linkedCaseData.contractDescription}
          </Grid>
        </Grid>
      )}
      {!isEditingClassification ? (
        <Grid container>
          <Grid md={4}>{t('classification')}:</Grid>
          <Grid md={8}>
            {getCurrentTranslation(currentCase.classification?.translations, language)}
            <IconButton
              disabled={!canEditCase}
              iconComponent={<EditIcon />}
              onClick={() => setIsEditingClassification(true)}
            />
          </Grid>
        </Grid>
      ) : (
        <Autocomplete
          noOptionsText={t('noOptions')}
          isOptionEqualToValue={(option, value) => option.id === value}
          options={caseClassifications}
          getOptionLabel={(option) => {
            const currentTranslation = getCurrentTranslation(option.translations, language);

            if (currentTranslation) return currentTranslation;

            const classification = caseClassifications.find((clas) => clas.id === option);

            if (classification) {
              return getCurrentTranslation(classification.translations, language);
            }

            return undefined;
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {getCurrentTranslation(option.translations, language)}
            </li>
          )}
          onChange={(e, value) => onChangeClassification(value)}
          value={selectedClassification || null}
          renderInput={(params) => (
            <TextField
              label={t('classification')}
              variant="standard"
              autoFocus
              onBlur={onBlurClassification}
              {...params}
            />
          )}
        />
      )}
      <Grid container>
        <Grid md={4}>{t('reference')}:</Grid>
        <Grid md={8}>{currentCase.currentTeamReference}</Grid>
      </Grid>
      {!!currentCase.channel && (
        <Grid container>
          <Grid md={4}>{t('channel')}:</Grid>
          <Grid md={8}>{t(`${lowerFirst(currentCase.channel)}`)}</Grid>
        </Grid>
      )}
      {!!currentCase.incidentDate && (
        <Grid container>
          <Grid md={4}>{incidentLabel}:</Grid>
          <Grid md={8}>
            {getMomentDescription(moment, currentCase.incidentDate, 'YYYY-MM-DD', false, true)}
          </Grid>
        </Grid>
      )}
      {currentCase.isRepairCase && (
        <Grid container>
          <Grid md={4}>{t('dateAndTimeOfReceipt')}:</Grid>
          <Grid md={8}>
            {getMomentDescription(
              moment,
              currentCase.notificationDate,
              'YYYY-MM-DDTHH-mm-ss',
              false,
              true,
            )}
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid md={4}>{t('created')}:</Grid>
        <Grid md={8}>
          {getMomentDescription(
            moment,
            currentCase.creationDate,
            'YYYY-MM-DDTHH-mm-ss',
            true,
            true,
          )}
        </Grid>
      </Grid>
      {!!currentCase.createdByUser && (
        <Grid container>
          <Grid md={4}>{t('createdBy')}:</Grid>
          <Grid md={8}>{currentCase.createdByUser}</Grid>
        </Grid>
      )}
      {!!currentCase.description && (
        <Grid container>
          <Grid md={4}>{t('description')}:</Grid>
          <Grid md={8}>{currentCase.description}</Grid>
        </Grid>
      )}
      {!!currentCase.remark && (
        <Grid container>
          <Grid md={4}>{t('remark')}:</Grid>
          <Grid md={8}>{currentCase.remark}</Grid>
        </Grid>
      )}
      <Grid container>
        <Grid md={4}>{t('priority')}:</Grid>
        <Grid md={8}>{t(`${lowerFirst(currentCase.priority)}`)}</Grid>
      </Grid>

      {currentTeam.maxQrTokens > 0 && (
        <Grid container>
          <Grid md={4}>{t('showOnBuildingPortal')}</Grid>
          <Grid md={8}>
            {currentCase.showOnBuildingPortal ? (
              <ValidIcon color="green" />
            ) : (
              <InvalidIcon color="red" />
            )}
          </Grid>
        </Grid>
      )}

      {(isRepairCase || isClaim) && (
        <Grid container>
          <Grid md={4}>{t('damageType')}:</Grid>
          <Grid md={8}>{getDamageTypeTranslation(t, currentCase.damageType)}</Grid>
        </Grid>
      )}
      {isClaim && (
        <>
          <Grid container>
            <Grid md={4}>{t('damageToCommonUnits')}:</Grid>
            <Grid md={8}>
              {currentCase.damageToCommonUnits ? (
                <ValidIcon color="green" />
              ) : (
                <InvalidIcon color="red" />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={4}>{t('damageToPrivateUnits')}:</Grid>
            <Grid md={8}>
              {currentCase.damageToPrivateUnits ? (
                <ValidIcon color="green" />
              ) : (
                <InvalidIcon color="red" />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={4}>{t('thirdPartyInvolved')}:</Grid>
            <Grid md={8}>
              {currentCase.thirdPartyInvolved ? (
                <ValidIcon color="green" />
              ) : (
                <InvalidIcon color="red" />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={4}>{t('verbalProcess')}:</Grid>
            <Grid md={8}>
              {currentCase.verbalProcess ? (
                <ValidIcon color="green" />
              ) : (
                <InvalidIcon color="red" />
              )}
            </Grid>
          </Grid>
          {currentCase.rikRequested && (
            <Grid container>
              <Grid md={4}>{t('rikRequested')}</Grid>
              <Grid md={8}>
                <ValidIcon color="green" />
              </Grid>
            </Grid>
          )}
          {!!currentCase.policeReportNumber && (
            <Grid container>
              <Grid md={4}>{t('policeReportNumber')}:</Grid>
              <Grid md={8}>{currentCase.policeReportNumber}</Grid>
            </Grid>
          )}
        </>
      )}
      {isComplaint && (
        <>
          {!!currentCase.linkedComplaintCaseId && (
            <Grid container>
              <Grid md={4}>{t('linkedCase')}</Grid>
              <Grid md={8}>
                <Link to={`/case/${currentCase.linkedComplaintCaseId}`}>
                  {currentCase.linkedComplaintCaseReference}
                </Link>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid>
              <p>
                <i>
                  {currentCase.isJustified ? (
                    <ValidIcon color="keypoint" />
                  ) : (
                    <InvalidIcon color="red" />
                  )}
                </i>{' '}
                {t('isJustified')}
              </p>
            </Grid>
          </Grid>
        </>
      )}
      {isRepairCase && (
        <>
          <Grid container>
            <Grid md={4}>{t('skill')}:</Grid>
            <Grid md={8}>
              {formatContractorSkillDefinition(
                currentCase.contractorSkillDefinition.translations,
                language,
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={4}>{t('consentOnCancellationFee')}</Grid>
            <Grid md={8}>
              {currentCase.consentOnCancellationFee ? (
                <ValidIcon color="green" />
              ) : (
                <InvalidIcon color="red" />
              )}
            </Grid>
          </Grid>
        </>
      )}
      {isRepairCase && !!contractor && (
        <Grid container>
          <Grid md={4}>{t('_contractor.title')}:</Grid>
          <Grid md={8}>{contractor.displayName}</Grid>
        </Grid>
      )}

      {!isComplaint && hasClients && (
        <Grid container>
          <Grid md={4}>{t('clientPage')}:</Grid>
          <Grid md={8}>
            <Link to={clientPageLink} target="_blank">
              <Tooltip title={t('openClientPage')}>
                <AccessibilityIcon />
              </Tooltip>
            </Link>{' '}
            <IconButton
              tooltip={t('copyLink')}
              iconComponent={<CopyIcon />}
              onClick={() => navigator.clipboard.writeText(clientPageLink)}
            />
          </Grid>
        </Grid>
      )}
      {isRepairCase && ownRelation.isOwner && (
        <>
          <Grid container>
            <Grid md={4}>{t('mandate')}:</Grid>
            <Grid md={8}>
              <IconButton
                disabled={!canEditCase}
                iconComponent={<EditIcon />}
                onClick={mandateToggle.show}
              />
            </Grid>
          </Grid>
          <EditMandateModal toggle={mandateToggle} caseId={currentCase.id} />
        </>
      )}

      {currentCase.statusReason && isCancelled && (
        <div className="grid grid-cols-3">
          <div>{t('caseCancelledReason')}</div>
          <div className="col-span-2">{currentCase.statusReason}</div>
        </div>
      )}
    </>
  );
};

CaseNotificationCardDetails.propTypes = {
  caseClassifications: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CaseNotificationCardDetails;
