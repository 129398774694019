import { Task } from '~/common/types/task/task';

import createRestClient from '../restClient';

const restClient = createRestClient();

const createTask = (data: any) => restClient.post('task', data);

const createTaskType = (data: any) => restClient.post('task/tasktype', data);

const deleteTaskType = (id: string) => restClient.delete(`task/tasktype/${id}`);

const finishTask = (id: string): Promise<Task> => restClient.put(`task/${id}/finish`);

const getTasks = (params: any) => restClient.get(`task`, { params });

const getTaskTypes = () => restClient.get(`task/tasktype.list`);

const snoozeTask = (id: string) => restClient.put(`task/${id}/snooze`);

const updateTask = (data: any) => restClient.put('task', data);

const updateTaskType = (data: any) => restClient.put('task/tasktype', data);

export default {
  createTask,
  createTaskType,
  deleteTaskType,
  finishTask,
  getTasks,
  getTaskTypes,
  snoozeTask,
  updateTask,
  updateTaskType,
};
