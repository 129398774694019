import { useContext, useEffect, useMemo } from 'react';

import {
  AutocompleteValue,
  UseAutocompleteOptionsArgs,
  UseAutocompleteOptionsRes,
} from './autocomplete.types';
import { BookYearOption } from '@/types/trpc/hoa';
import { BookyearStatus } from '~/common/enums';

import { AppContext } from '@/shared/context/context';
import { trpc } from '@/config/trpc';
import useAutocompleteOptions from './useAutocompleteOptions';

type Options<
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
> = {
  activeOnly?: boolean;
  homeownerAssociationId: string | null | undefined;
  useGlobalAsDefault?: boolean;
} & UseAutocompleteOptionsArgs<BookYearOption[], TMultiple, TDisableClearable>;

const useBookYearAutocomplete = <
  TMultiple extends boolean | undefined = undefined,
  TDisableClearable extends boolean | undefined = undefined,
>({
  activeOnly = false,
  callback,
  disableClearable,
  homeownerAssociationId,
  multiple,
  selected,
  useGlobalAsDefault = false,
  ...args
}: Options<TMultiple, TDisableClearable>): UseAutocompleteOptionsRes<
  BookYearOption[],
  TMultiple
> => {
  type Value = AutocompleteValue<BookYearOption, TMultiple, TDisableClearable>;

  const { data: options, isLoading } = trpc.hoa.bookYear.autocomplete.useQuery(
    {
      bookYearStatus: activeOnly ? [BookyearStatus.Open] : undefined,
      homeownerAssociationId: homeownerAssociationId as string,
    },
    { enabled: !!homeownerAssociationId },
  );
  const { ui } = useContext(AppContext);

  const autocompleteProps = useAutocompleteOptions({
    callback,
    data: options,
    disableClearable,
    isLoading,
    multiple,
    selected,
    ...args,
  });

  const fallbackOption = useMemo(() => {
    if (!options) return null;

    let matchingOption;

    if (useGlobalAsDefault && ui.currentBookYearId) {
      matchingOption = options.find((option) => option.value === ui.currentBookYearId);
    } else {
      const currentYear = new Date().getFullYear();

      matchingOption = options.find((option) => option.data.year === currentYear);
    }

    return matchingOption || null;
  }, [options, ui.currentBookYearId, useGlobalAsDefault]);

  useEffect(() => {
    const hasValue = Array.isArray(autocompleteProps.value)
      ? !!autocompleteProps.value.length
      : !!autocompleteProps.value;

    if (!fallbackOption || hasValue) return;

    const optionToSelect = multiple ? [fallbackOption] : fallbackOption;

    callback(optionToSelect as Value);
  }, [autocompleteProps.value, callback, fallbackOption, multiple]);

  return {
    ...autocompleteProps,
    disabled: !homeownerAssociationId || autocompleteProps.disabled,
  };
};

export default useBookYearAutocomplete;
