import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@mui/joy';
import { Case } from '@/types/cases';
import CaseType from '@/shared/enums/CaseType';
import { Task } from '~/common/types/task/task';

import { distinct, formatDate } from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import { useTaskActions } from '@/mutations';

import { CompletedIcon, TaskIcon } from '@/shared/icons/Icons';

type Props = {
  task: Task;
  currentCase?: Case;
};

const FinishTaskButton: React.FC<Props> = ({ task, currentCase }) => {
  const [hover, setHover] = useState(false);

  const { finishTask, isFinishingTask } = useTaskActions();
  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation();

  const isTaskFinished = !!task.completedBy?.length || !!task.completedDate;

  const isDisabled = useMemo(() => {
    if (isTaskFinished) return true;

    if (
      currentCase &&
      currentTeam &&
      currentCase.caseType === CaseType.Repair &&
      currentTeam.id === currentCase.relations.find((r) => r.isOwner)?.teamId
    ) {
      return false;
    }

    const taskExecutors = task.taskUsers;
    const executorTeams = taskExecutors.map((te) => te.teamId).filter(distinct);
    return !(currentTeam?.id && executorTeams.includes(currentTeam.id));
  }, [task, isTaskFinished, currentCase, currentTeam]);

  const tooltip = useMemo(() => {
    if (!isTaskFinished) return t('finish');

    if (task.completedByUsername) {
      return `${task.completedByUsername}, ${formatDate(task.completedDate)}`;
    }

    return `${t('digitalAssistantVera')}, ${formatDate(task.completedDate)}`;
  }, [isTaskFinished, t, task.completedByUsername, task.completedDate]);

  return (
    <Tooltip arrow title={tooltip}>
      <div>
        <IconButton
          color={isTaskFinished ? 'success' : 'primary'}
          disabled={isDisabled || isFinishingTask}
          variant="plain"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => finishTask(task.id)}
        >
          {isTaskFinished || hover ? <CompletedIcon /> : <TaskIcon />}
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default FinishTaskButton;
