import { Accept } from 'react-dropzone';

import { localStorageKeys } from '@/shared/utils/constants';

export type State = {
  budgets: {
    currentBudgetId: string;
    columnCount: number;
    toggles: {
      create: boolean;
      edit: boolean;
      manual: boolean;
    };
  };
  case: {
    stepper: {
      repairCase: {
        step: number;
        involvedParties: {
          id: string | null;
        };
      };
    };
    toggles: {
      createRepairCase: boolean;
      involvedPartyInfo: boolean;
      showAddress: boolean;
      uploadEstimate: boolean;
      uploadFile: {
        modal: boolean;
        accept?: Accept;
        multiple: boolean;
      };
    };
  };
  currentTeamId?: string;
  currentBookYearId: string | undefined;
  dashboard: {
    columnCount: number;
    toggles: {
      header: boolean;
      openTasksByTypeChart: boolean;
      openTasksTable: boolean;
    };
  };
  files: {
    main: File | null;
    temporary: {
      uri: string;
      name: string;
      blobContainerName: string;
    }[];
  };
  hoa: {
    toggles: {
      createReports: boolean;
      sendEmail: boolean;
    };
  };
  toggles: {
    mainMenu: boolean;
    pageNotFound: boolean;
  };
};

type Action = {
  type: string;
  payload?: any;
};

export const initialUIState: State = {
  budgets: {
    columnCount: 1,
    currentBudgetId: '',
    toggles: {
      create: false,
      edit: false,
      manual: false,
    },
  },
  case: {
    stepper: {
      repairCase: {
        involvedParties: {
          id: null,
        },
        step: 1,
      },
    },
    toggles: {
      createRepairCase: false,
      involvedPartyInfo: false,
      showAddress: true,
      uploadEstimate: false,
      uploadFile: {
        modal: false,
        multiple: false,
      },
    },
  },
  currentBookYearId: undefined,
  currentTeamId: localStorage.getItem(localStorageKeys.teamId) || undefined,
  dashboard: {
    columnCount: 2,
    toggles: {
      header: true,
      openTasksByTypeChart: true,
      openTasksTable: true,
    },
  },
  files: {
    main: null,
    temporary: [],
  },
  hoa: {
    toggles: {
      createReports: false,
      sendEmail: false,
    },
  },
  toggles: {
    mainMenu: true,
    pageNotFound: false,
  },
};

export const actions = {
  BUDGETS_SET_COLUMN_COUNT: 'BUDGETS_SET_COLUMN_COUNT',
  BUDGETS_SET_CURRENT_BUDGET_ID: 'BUDGETS_SET_CURRENT_BUDGET_ID',
  BUDGETS_TOGGLE_CREATE: 'BUDGETS_TOGGLE_CREATE',
  BUDGETS_TOGGLE_EDIT: 'BUDGETS_TOGGLE_EDIT',
  BUDGETS_TOGGLE_MANUAL: 'BUDGETS_TOGGLE_MANUAL',
  BUDGETS_TOGGLE_STANDARD_GLA: 'BUDGETS_TOGGLE_STANDARD_GLA',
  BUILDING_TOGGLE_CREATE: 'BUILDING_TOGGLE_CREATE',
  CASE_STEPPER_SET_REPAIR_CASE_STEP: 'CASE_STEPPER_SET_REPAIR_CASE_STEP',
  CASE_TOGGLE_CREATE_REPAIR_CASE: 'CASE_TOGGLE_CREATE_REPAIR_CASE',
  CASE_TOGGLE_INVOLVED_PARTY_INFO: 'CASE_TOGGLE_INVOLVED_PARTY_INFO',
  CASE_TOGGLE_SHOW_ADDRESS: 'CASE_TOGGLE_SHOW_ADDRESS',
  CASE_TOGGLE_UPLOAD_ESTIMATE: 'CASE_TOGGLE_UPLOAD_ESTIMATE',
  CASE_TOGGLE_UPLOAD_FILE: 'CASE_TOGGLE_UPLOAD_FILE',
  CASE_TOGGLE_UPLOAD_FILES: 'CASE_TOGGLE_UPLOAD_FILES',
  DASHBOARD_SET_COLUMN_COUNT: 'DASHBOARD_SET_COLUMN_COUNT',
  DASHBOARD_TOGGLE_HEADER: 'DASHBOARD_TOGGLE_HEADER',
  DASHBOARD_TOGGLE_OPEN_TASKS_CHART: 'DASHBOARD_TOGGLE_OPEN_TASKS_CHART',
  DASHBOARD_TOGGLE_OPEN_TASKS_TABLE: 'DASHBOARD_TOGGLE_OPEN_TASKS_TABLE',
  FILES_SET_MAIN_FILE: 'FILES_SET_MAIN_FILE',
  FILES_SET_TEMPORARY_FILES: 'FILES_SET_TEMPORARY_FILES',
  HOA_TOGGLE_CREATE_REPORTS: 'HOA_TOGGLE_CREATE_REPORTS',
  HOA_TOGGLE_SEND_EMAIL: 'HOA_TOGGLE_SEND_EMAIL',
  SAVE_CURRENT_TEAM_ID: 'SAVE_CURRENT_TEAM_ID',
  SET_CURRENT_BOOK_YEAR_ID: 'SET_CURRENT_BOOK_YEAR_ID',
  TOGGLE_MAIN_MENU: 'TOGGLE_MAIN_MENU',
  TOGGLE_PAGE_NOT_FOUND: 'TOGGLE_PAGE_NOT_FOUND',
};

// eslint-disable-next-line default-param-last
const uiReducer = (state: State = initialUIState, action: Action): State => {
  switch (action.type) {
    case actions.BUDGETS_SET_COLUMN_COUNT:
      return {
        ...state,
        budgets: {
          ...state.budgets,
          columnCount: action.payload,
        },
      };
    case actions.BUDGETS_SET_CURRENT_BUDGET_ID:
      return {
        ...state,
        budgets: {
          ...state.budgets,
          currentBudgetId: action.payload,
        },
      };
    case actions.BUDGETS_TOGGLE_CREATE:
      return {
        ...state,
        budgets: {
          ...state.budgets,
          toggles: { ...state.budgets.toggles, create: !state.budgets.toggles.create },
        },
      };
    case actions.BUDGETS_TOGGLE_EDIT:
      return {
        ...state,
        budgets: {
          ...state.budgets,
          toggles: { ...state.budgets.toggles, edit: !state.budgets.toggles.edit },
        },
      };
    case actions.BUDGETS_TOGGLE_MANUAL:
      return {
        ...state,
        budgets: {
          ...state.budgets,
          toggles: {
            ...state.budgets.toggles,
            manual: action.payload ?? !state.budgets.toggles.manual,
          },
        },
      };
    case actions.CASE_STEPPER_SET_REPAIR_CASE_STEP:
      return {
        ...state,
        case: {
          ...state.case,
          stepper: {
            ...state.case.stepper,
            repairCase: {
              ...state.case.stepper.repairCase,
              step: action.payload,
            },
          },
        },
      };
    case actions.CASE_TOGGLE_CREATE_REPAIR_CASE:
      return {
        ...state,
        case: {
          ...state.case,
          toggles: {
            ...state.case.toggles,
            createRepairCase: !state.case.toggles.createRepairCase,
          },
        },
      };
    case actions.CASE_TOGGLE_INVOLVED_PARTY_INFO:
      return {
        ...state,
        case: {
          ...state.case,
          stepper: {
            ...state.case.stepper,
            repairCase: {
              ...state.case.stepper.repairCase,
              involvedParties: {
                ...state.case.stepper.repairCase.involvedParties,
                id: action.payload,
              },
            },
          },
          toggles: {
            ...state.case.toggles,
            involvedPartyInfo: !state.case.toggles.involvedPartyInfo,
          },
        },
      };
    case actions.CASE_TOGGLE_SHOW_ADDRESS:
      return {
        ...state,
        case: {
          ...state.case,
          toggles: {
            ...state.case.toggles,
            showAddress: !state.case.toggles.showAddress,
          },
        },
      };
    case actions.CASE_TOGGLE_UPLOAD_ESTIMATE:
      return {
        ...state,
        case: {
          ...state.case,
          toggles: { ...state.case.toggles, uploadEstimate: !state.case.toggles.uploadEstimate },
        },
      };
    case actions.CASE_TOGGLE_UPLOAD_FILE:
      return {
        ...state,
        case: {
          ...state.case,
          toggles: {
            ...state.case.toggles,
            uploadFile: {
              ...state.case.toggles.uploadFile,
              accept: action.payload?.accept,
              modal: !state.case.toggles.uploadFile.modal,
              multiple: false,
            },
          },
        },
      };
    case actions.CASE_TOGGLE_UPLOAD_FILES:
      return {
        ...state,
        case: {
          ...state.case,
          toggles: {
            ...state.case.toggles,
            uploadFile: {
              ...state.case.toggles.uploadFile,
              accept: action.payload?.accept,
              modal: !state.case.toggles.uploadFile.modal,
              multiple: true,
            },
          },
        },
      };
    case actions.TOGGLE_MAIN_MENU:
      return {
        ...state,
        toggles: { ...state.toggles, mainMenu: !state.toggles.mainMenu },
      };
    case actions.TOGGLE_PAGE_NOT_FOUND:
      return {
        ...state,
        toggles: { ...state.toggles, pageNotFound: !state.toggles.pageNotFound },
      };
    case actions.FILES_SET_MAIN_FILE: {
      return {
        ...state,
        files: {
          ...state.files,
          main: action.payload ? action.payload[0] : null,
        },
      };
    }
    case actions.FILES_SET_TEMPORARY_FILES:
      return {
        ...state,
        files: {
          ...state.files,
          temporary: action.payload,
        },
      };
    case actions.HOA_TOGGLE_CREATE_REPORTS:
      return {
        ...state,
        hoa: {
          ...state.hoa,
          toggles: {
            ...state.hoa.toggles,
            createReports: !state.hoa.toggles.createReports,
          },
        },
      };
    case actions.HOA_TOGGLE_SEND_EMAIL:
      return {
        ...state,
        hoa: {
          ...state.hoa,
          toggles: {
            ...state.hoa.toggles,
            sendEmail: !state.hoa.toggles.sendEmail,
          },
        },
      };
    case actions.SAVE_CURRENT_TEAM_ID:
      localStorage.setItem(localStorageKeys.teamId, action.payload);
      return {
        ...state,
        currentTeamId: action.payload,
      };
    case actions.SET_CURRENT_BOOK_YEAR_ID:
      return {
        ...state,
        currentBookYearId: action.payload,
      };
    case actions.DASHBOARD_TOGGLE_OPEN_TASKS_CHART:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          toggles: {
            ...state.dashboard.toggles,
            openTasksByTypeChart: !state.dashboard.toggles.openTasksByTypeChart,
          },
        },
      };
    case actions.DASHBOARD_TOGGLE_OPEN_TASKS_TABLE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          toggles: {
            ...state.dashboard.toggles,
            openTasksTable: !state.dashboard.toggles.openTasksTable,
          },
        },
      };
    case actions.DASHBOARD_TOGGLE_HEADER:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          toggles: {
            ...state.dashboard.toggles,
            header: !state.dashboard.toggles.header,
          },
        },
      };
    case actions.DASHBOARD_SET_COLUMN_COUNT:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          columnCount: action.payload,
        },
      };
    default:
      return state;
  }
};

export default uiReducer;
