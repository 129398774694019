import { Autocomplete, FormControl, FormLabel, Typography } from '@mui/joy';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RouterOutputs, trpc } from '@/config/trpc';

import { InfoIcon } from '@/shared/icons/Icons';
import KpcTooltip from '@/shared/components/Tooltips/KpcTooltip';

type scoreOption = RouterOutputs['homeScore']['autocompleteForContractorTeam'][number];

type Props = {
  contractorTeamId: string;
};
const HomeReportDetails: FC<Props> = ({ contractorTeamId }) => {
  const { t } = useTranslation();
  const [selectedScore, setSelectedScore] = useState<scoreOption | null>(null);
  const { data: scores, isLoading } = trpc.homeScore.autocompleteForContractorTeam.useQuery(
    contractorTeamId,
    {
      enabled: !!contractorTeamId,
    },
  );

  useEffect(() => {
    if (scores && scores.length > 0) {
      setSelectedScore(scores[0]);
    }
  }, [scores]);

  return (
    <div className="flex flex-col space-y-4">
      <FormControl>
        <FormLabel>{t('homeScore.report')}</FormLabel>
        <Autocomplete
          loading={isLoading}
          value={selectedScore}
          options={scores ?? []}
          onChange={(_, value) => setSelectedScore(value)}
        />
      </FormControl>

      {selectedScore && (
        <>
          <div className="grid grid-cols-3 py-4">
            <div className="flex flex-col items-center">
              <Typography
                endDecorator={
                  <KpcTooltip title={t('_contractor.assign.happinessInfo')}>
                    <InfoIcon />
                  </KpcTooltip>
                }
              >
                {t('homeScore.form.happinessPercentage')}
              </Typography>
              <p>{selectedScore.happiness}</p>
            </div>
            <div className="flex flex-col items-center">
              <Typography
                endDecorator={
                  <KpcTooltip title={t('_contractor.assign.operationalManagementInfo')}>
                    <InfoIcon />
                  </KpcTooltip>
                }
              >
                {t('homeScore.form.operationalManagementPercentage')}
              </Typography>
              <p>{selectedScore.operationalManagement}</p>
            </div>
            <div className="flex flex-col items-center">
              <Typography
                endDecorator={
                  <KpcTooltip title={t('_contractor.assign.effectivenessInfo')}>
                    <InfoIcon />
                  </KpcTooltip>
                }
              >
                {t('homeScore.form.effectivenessPercentage')}
              </Typography>
              <p>{selectedScore.effectiveness}</p>
            </div>
          </div>

          <p className="bold text-center">
            {t('homeScore.score')}: {selectedScore.score ?? '-'}
          </p>
        </>
      )}
    </div>
  );
};

export default HomeReportDetails;
