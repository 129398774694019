import { useMemo } from 'react';

import { GeneralLedgerAccountType, ProvisionType } from '~/common/enums';
import { UseAutocompleteOptionsArgs, UseAutocompleteOptionsRes } from './autocomplete.types';
import { getDefaultAccountTranslation } from '@/shared/utils/hoa/hoaUtils';
import { HomeownerAssociationAccountOption } from '@/types/trpc/hoa';
import { trpc } from '@/config/trpc';
import useAutocompleteOptions from './useAutocompleteOptions';

const glaTypeMapping = {
  [ProvisionType.FixedWorkCapital]: GeneralLedgerAccountType.FixedWorkCapital,
  [ProvisionType.SpareCapital]: GeneralLedgerAccountType.ReserveCapital,
  [ProvisionType.WorkCapital]: GeneralLedgerAccountType.WorkCapital,
};

type Options<
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
> = {
  homeownerAssociationId: string | null | undefined;
  startsWith?: string[];
  provisionType?: ProvisionType;
} & UseAutocompleteOptionsArgs<HomeownerAssociationAccountOption[], TMultiple, TDisableClearable>;

const useHoaAccountAutocomplete = <
  TMultiple extends boolean | undefined = undefined,
  TDisableClearable extends boolean | undefined = undefined,
>({
  homeownerAssociationId,
  selected,
  startsWith = [],
  provisionType,
  ...args
}: Options<TMultiple, TDisableClearable>): UseAutocompleteOptionsRes<
  HomeownerAssociationAccountOption[],
  TMultiple
> => {
  const { data: options, isLoading } = trpc.hoa.account.autocomplete.useQuery(
    {
      homeownerAssociationId: homeownerAssociationId as string,
      startsWith: startsWith || [],
    },
    { enabled: !!homeownerAssociationId },
  );

  const translatedOptions = useMemo(() => {
    if (!options) return [];

    const translateLabel = (option: HomeownerAssociationAccountOption) => {
      const { label } = option;
      const { defaultAccount, number } = option.data;

      return getDefaultAccountTranslation(label, number, defaultAccount);
    };

    return options
      .filter((o) => !provisionType || o.data.type === glaTypeMapping[provisionType])
      .map((option) => ({
        ...option,
        label: translateLabel(option),
      }));
  }, [provisionType, options]);

  const autocompleteProps = useAutocompleteOptions({
    data: translatedOptions,
    isLoading,
    selected,
    ...args,
  });

  return {
    ...autocompleteProps,
    disabled: !homeownerAssociationId || autocompleteProps.disabled,
  };
};

export default useHoaAccountAutocomplete;
