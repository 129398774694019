import { Link, Portal, Skeleton } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SortingMode } from 'ka-table/enums';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { formatData, prefillRelations } from './utils';
import { getRelationsWithSubordinates, makeId } from '@/shared/utils/helpers';
import { useGetBuilding, useGetCentralCompany, useGetCompany } from '@/queries';
import { AppContext } from '@/shared/context/context';
import RelationType from '@/shared/enums/RelationType';

import AddUpdateCaseRelationForm from '@/containers/Cases/CaseLayout/BodySections/Forms/AddUpdateCaseRelationForm';
import CaseRelationCustomCellDetails from '@/containers/Cases/CaseLayout/BodySections/CaseRelationCustomCellDetails';
import CaseRelationCustomCells from '@/containers/Cases/CaseLayout/BodySections/CaseRelationCustomCells';
import IconButton from '@/shared/components/buttons/IconButton';
import InvolvedPartiesAddBuildingAsClient from './InvolvedPartiesAddBuildingAsClient';
import InvolvedPartiesComplianceVisualizer from './InvolvedPartiesComplianceVisualizer';
import KeypointTable from '@/shared/components/table/KeypointTable';

const propTypes = {
  isAddingInvolvedParty: PropTypes.bool.isRequired,
  onAddingInvolvedParty: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  popperContainer: PropTypes.object,
};

const InvolvedPartiesStep = ({
  popperContainer = null,
  onAddingInvolvedParty,
  isAddingInvolvedParty,
}) => {
  const { currentTeam } = useContext(AppContext);

  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const { policySelector } = values;
  const { policies } = useSelector((state) => state.policies);
  const [formattedData, setFormattedData] = useState(values.caseRelations ?? []);
  const [isAddingOrEditing, setIsAddOrEditModalOpen] = useState(isAddingInvolvedParty);
  const [relationIdToEdit, setRelationIdToEdit] = useState(null);
  const { createCaseAskForClient } = currentTeam;

  const { company: currentTeamCompany, companyIsLoading: currentTeamCompanyIsLoading } =
    useGetCompany(currentTeam?.companyId);

  const handleInvolvedPartyModal = () => {
    setIsAddOrEditModalOpen(true);
    onAddingInvolvedParty(true);
  };

  const defaultOptions = {
    columns: [
      {
        key: 'type',
        width: 30,
      },
      {
        key: 'name',
        title: t('name'),
      },
      {
        key: 'contact',
        title: t('contact'),
      },
      {
        key: 'address',
        title: t('_address.label'),
      },
      {
        key: 'involvementType',
        title: t('involvementType'),
      },
      {
        key: 'id',
        width: 100,
      },
    ],
    rowKeyField: 'id',
    sortingMode: SortingMode.None,
  };

  const currentRelations = useMemo(() => {
    if (!formattedData?.length) {
      return [];
    }
    return formattedData.map((fd) => ({ ...fd, displayName: fd.name }));
  }, [formattedData]);

  const showEditRelationModal = useCallback(
    (relationId) => {
      setRelationIdToEdit(relationId);
      setIsAddOrEditModalOpen(true);
      onAddingInvolvedParty(true);
    },
    [onAddingInvolvedParty],
  );

  const handleCancelAddOrEditCaseRelation = () => {
    setIsAddOrEditModalOpen(false);
    onAddingInvolvedParty(false);
    setRelationIdToEdit(null);
  };

  const getFormattedCaseRelationToUpdate = () => {
    const relationToUpdate = values.caseRelations.find((fd) => fd.id === relationIdToEdit);
    if (!relationToUpdate) {
      return null;
    }
    let relationType = null;

    if (relationToUpdate.contactId) {
      relationType = RelationType.Contact;
    } else if (relationToUpdate.companyId) {
      relationType = RelationType.Company;
    } else if (relationToUpdate.teamId) {
      relationType = RelationType.Team;
    }

    const { subordinate, name, type, vatnumber, address, ...involvedRelation } = relationToUpdate;

    return {
      displayName: name,
      parentRelationId: subordinate,
      relationType,
      role: type,
      subordinateTo: subordinate,
      teamName: name,
      vat: vatnumber,
      ...address,
      ...involvedRelation,
    };
  };

  const addOrEditRelation = (updatedRelation) => {
    const { addressLine, city, postalCode, country } = updatedRelation;
    const relation = {
      relationAddressAddressLine: addressLine,
      relationAddressCity: city,
      relationAddressCountry: country,
      relationAddressPostalCode: postalCode,
      ...updatedRelation,
    };
    if (relationIdToEdit) {
      setFormattedData((prev) =>
        getRelationsWithSubordinates(
          prev.map((fd) =>
            fd.id === relationIdToEdit
              ? formatData({ ...relation, relationId: relationIdToEdit })
              : { ...fd, relationId: relationIdToEdit },
          ),
        ),
      );
    } else {
      const formatted = formatData(relation);
      setFormattedData((prev) =>
        getRelationsWithSubordinates([
          ...prev,
          {
            hasAccess: relation.hasAccess,
            id: makeId(7),
            type: relation.role,
            ...formatted,
          },
        ]),
      );
    }

    setIsAddOrEditModalOpen(false);
    onAddingInvolvedParty(false);
    setRelationIdToEdit(null);
  };

  const getCustomCells = useCallback(
    (data) => {
      const { rowData } = data;
      const { id } = rowData;

      const relation = formattedData.find((rel) => rel.id === id);
      if (relation == null) {
        return <Skeleton height={200} />;
      }
      return (
        <CaseRelationCustomCells
          onDeleteCallback={(relId) =>
            setFormattedData((prev) =>
              getRelationsWithSubordinates(prev.filter((fd) => fd.id !== relId)),
            )
          }
          onEditCallback={(relId) => showEditRelationModal(relId)}
          canEdit
          isDeletable
          disableDetailsButton={relation.teamId === currentTeam.id}
          {...data}
        />
      );
    },
    [currentTeam.id, formattedData, showEditRelationModal],
  );

  const getCustomCellDetails = useCallback(
    (data) => {
      const { rowData } = data;
      const { id } = rowData;

      const relation = formattedData.find((rel) => rel.id === id);
      if (relation == null) {
        return <Skeleton height={200} />;
      }
      return (
        <CaseRelationCustomCellDetails
          relation={relation}
          currentTeamIsOwner
          canEdit
          canManageOwnTeam
          onSaveCallback={(relId, updated) =>
            setFormattedData((prev) =>
              getRelationsWithSubordinates(
                prev.map((fd) =>
                  fd.id === relId
                    ? {
                        ...fd,
                        hasAccess: updated.hasAccess,
                        relationReference: updated.reference,
                        remark: updated.remark,
                      }
                    : { ...fd },
                ),
              ),
            )
          }
        />
      );
    },
    [formattedData],
  );

  useEffect(() => {
    if (values.caseRelations?.length || currentTeamCompanyIsLoading) {
      return;
    }
    const prefilledRelations = prefillRelations(
      currentTeam,
      currentTeamCompany,
      policySelector,
      policies,
    );

    setFormattedData(prefilledRelations);
  }, [
    currentTeam,
    currentTeamCompany,
    currentTeamCompanyIsLoading,
    policies,
    policySelector,
    values.caseRelations?.length,
  ]);

  useEffect(() => {
    setFieldValue('caseRelations', formattedData);
  }, [setFieldValue, formattedData]);

  const { building, buildingIsLoading } = useGetBuilding({ buildingId: values.buildingId });
  const { centralCompany } = useGetCentralCompany(building?.centralCompanyId);

  const handleAddBuildingAsClient = (data) => {
    const id = makeId(7);
    const buildingRelation = {
      address: {
        relationAddressAddressLine: building.address.addressLine,
        relationAddressCity: building.address.city,
        relationAddressCountry: building.address.country,
        relationAddressPostalCode: building.address.postalCode,
      },
      administrators: building.administrators,
      companyId: null,
      contactId: null,
      emailAddresses: [data.emailAddress],
      firstName: null,
      gender: 'Unknown',
      hasAccess: true,
      iban: building.iban,
      id,
      identifier: id,
      isClient: true,
      isPolicyManager: false,
      isRequestor: false,
      language: 'Dutch',
      lastName: null,
      mobilePhoneNumber: {
        countryCode: '',
        number: '',
      },
      name: centralCompany?.name ?? building.name,
      phoneNumber: {
        countryCode: '',
        number: '',
      },
      policyId: null,
      policyRelationId: null,
      relationType: null,
      remark: null,
      role: null,
      teamCentralCompanyId: building?.centralCompanyId,
      teamId: null,
      type: null,
      vat: centralCompany?.vatNumber,
    };

    setFormattedData(formattedData.concat(buildingRelation));
  };

  return (
    <div>
      {!isAddingOrEditing ? (
        <div>
          <div className="flex justify-between">
            <h3 className="font-bold">{t('involvedParties')}</h3>
            <IconButton
              size="2x"
              onClick={() => handleInvolvedPartyModal(true)}
              color="keypoint"
              icon="plus"
            />
          </div>
          <span>{t('addInvolvedPartyInfo')}</span>{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              handleInvolvedPartyModal(true);
            }}
          >
            {t('addInvolvedPartyClick')}
          </Link>
          <InvolvedPartiesComplianceVisualizer
            relations={values.caseRelations}
            createCaseAskForClient={createCaseAskForClient}
          />
          {!buildingIsLoading && (
            <InvolvedPartiesAddBuildingAsClient
              enabled={!!values.buildingId}
              onSubmit={handleAddBuildingAsClient}
            />
          )}
          <KeypointTable
            hideActionbar
            defaultOptions={defaultOptions}
            formattedData={formattedData}
            childComponents={{
              cellText: {
                content: getCustomCells,
              },
              detailsRow: {
                content: getCustomCellDetails,
              },
            }}
            bordered
            localStorageKey="add-insurance-case-relations-table-option"
          />
        </div>
      ) : (
        <Portal container={popperContainer?.current}>
          <AddUpdateCaseRelationForm
            buildingId={values.buildingId}
            caseRelationToUpdate={getFormattedCaseRelationToUpdate()}
            onSubmit={(newRelation) => addOrEditRelation(newRelation)}
            onCancel={() => handleCancelAddOrEditCaseRelation()}
            currentCaseRelations={currentRelations}
            editMode={relationIdToEdit != null}
          />
        </Portal>
      )}
    </div>
  );
};

InvolvedPartiesStep.propTypes = propTypes;

export default InvolvedPartiesStep;
