import { Box, Chip, TextField } from '@mui/material';
import { ClickAwayListener, Popper as PopperUnstyled } from '@mui/base';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AddressPropTypes from '../../prop-types/AddressPropTypes';
import { makeId } from '../../utils/helpers';
import ReferencePropType from '../../prop-types/ReferencePropType';
import { trpc } from '@/config/trpc';
import { useGetBuildings } from '../../../queries';

import { AddressIcon, BuildingIcon, ValidCompanyIcon } from '../../icons/Icons';
import BuildingSelectorList from './Components/BuildingSelectorList';
import useOutsideAlerter from '../../hooks/UseOutsideAlerter';

const SelectBuilding = ({
  addressToSelect,
  alwaysOpen,
  buildingIdsToSelect,
  buildingsToExclude,
  centralCompanyId,
  disablePortal,
  errorText,
  hideAddAddress,
  hideAddBuilding,
  isRequired,
  label,
  onBlur,
  onBuildingSelectChanged,
  popperContainer,
  prefillAddress,
  selectOnly,
  selectSingle,
  showInactiveSwitch,
  showSuggestions,
}) => {
  const { buildings, buildingsIsLoading } = useGetBuildings();

  const { t } = useTranslation(['common', 'errors']);

  const selectorMenuRef = React.useRef();
  const { isInside, setOutsideAlerterActive } = useOutsideAlerter(selectorMenuRef);

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isAddingNewBuilding, setIsAddingNewBuilding] = useState(false);
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);

  const [formatedBuildings, setFormatedBuildings] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);

  const [query, setQuery] = useState('');

  const anchorRef = useRef();

  const collapseSelect = useCallback(() => {
    setIsSelectOpen(false);
    setOutsideAlerterActive(false);
    setIsAddingNewAddress(false);
    setIsAddingNewBuilding(false);
    setQuery('');

    if (isInside) {
      onBlur();
    }
  }, [isInside, onBlur, setOutsideAlerterActive]);

  const handleSelectOrDeselect = useCallback(
    (building) => {
      setQuery('');
      let currentSelectList = formatedBuildings;
      let currentSelectedItems = selectedBuildings;
      const currentIsAlreadySelected = currentSelectedItems.some((i) => i.id === building.id);

      if (currentIsAlreadySelected) {
        if (!selectSingle) {
          currentSelectedItems = currentSelectedItems.filter((i) => i.id !== building.id);
          currentSelectList = [...currentSelectList, building];
        } else {
          currentSelectedItems = [];
          currentSelectList = [...currentSelectList, building];
        }
        setSelectedBuildings(
          currentSelectedItems.sort((a, b) => a.display.localeCompare(b.display)),
        );
        setFormatedBuildings(currentSelectList.sort((a, b) => a.display.localeCompare(b.display)));
        onBuildingSelectChanged(
          currentSelectedItems.sort((a, b) => a.display.localeCompare(b.display)),
        );
      } else {
        if (!selectSingle) {
          currentSelectedItems = [...currentSelectedItems, building];
          currentSelectList = currentSelectList.filter((i) => i.id !== building.id);
        } else {
          if (currentSelectedItems.length > 0) {
            const [currentlySelected] = currentSelectedItems;
            currentSelectList = [...currentSelectList, currentlySelected];
          }
          currentSelectedItems = [building];
          currentSelectList = currentSelectList.filter((i) => i.id !== building.id);
          collapseSelect();
        }
        setSelectedBuildings(
          currentSelectedItems.sort((a, b) => a.display.localeCompare(b.display)),
        );
        setFormatedBuildings(currentSelectList.sort((a, b) => a.display.localeCompare(b.display)));
        onBuildingSelectChanged(
          currentSelectedItems.sort((a, b) => a.display.localeCompare(b.display)),
        );
      }
    },
    [collapseSelect, formatedBuildings, onBuildingSelectChanged, selectSingle, selectedBuildings],
  );

  const formatBuilding = useCallback((building) => {
    let display = `${building.name}`;
    if (building.address && building.address.city && building.address.addressLine) {
      display = `${display} (${building.address.addressLine}, ${building.address.city})`;
    }
    return {
      active: building.isActive,
      address: building.address,
      centralCompanyId: building.centralCompanyId,
      display,
      id: building.id,
      isAddress: false,
      linkedToCentralCompany: 'centralCompanyId' in building && building.centralCompanyId !== null,
    };
  }, []);

  const formatAddress = useCallback((address) => {
    let display = '';
    if (address.city && address.addressLine) {
      display = `${address.addressLine}, ${address.city}`;
    }
    return {
      address,
      display,
      id: makeId(7),
      isAddress: true,
      linkedToCentralCompany: false,
    };
  }, []);

  const filteredBuildings = useMemo(() => {
    if (!query || query.length === 0) {
      return formatedBuildings;
    }
    return formatedBuildings.filter((c) => c.display.toLowerCase().includes(query));
  }, [formatedBuildings, query]);

  const suggestions = filteredBuildings.filter(
    (b) => b.centralCompanyId === centralCompanyId && b.centralCompanyId != null,
  );

  const formatBuildings = useCallback(
    () =>
      setFormatedBuildings(
        buildings
          .filter((building) => !selectedBuildings.some((b) => b.id === building.id))
          .filter((building) => !buildingsToExclude.some((b) => b.id === building.id))
          .map((building) => formatBuilding(building))
          .sort((a, b) => a.display.localeCompare(b.display)),
      ),
    [buildings, formatBuilding, selectedBuildings, buildingsToExclude],
  );

  useEffect(() => {
    if (buildingsIsLoading) {
      return;
    }
    formatBuildings();
  }, [buildingsIsLoading, formatBuildings]);

  const [buildingPreSelectInited, setBuildingPreSelectInited] = useState(false);
  useEffect(() => {
    if (
      !buildingPreSelectInited &&
      buildingIdsToSelect.length > 0 &&
      formatedBuildings.length > 0
    ) {
      formatedBuildings
        .filter((b) => buildingIdsToSelect.includes(b.id))
        .forEach((b) => handleSelectOrDeselect(b));
      setBuildingPreSelectInited(true);
    }
  }, [buildingIdsToSelect, buildingPreSelectInited, formatedBuildings, handleSelectOrDeselect]);

  const [addressPreSelectInited, setAddressPreSelectInited] = useState(false);
  useEffect(() => {
    if (!addressPreSelectInited && addressToSelect != null) {
      handleSelectOrDeselect(formatAddress(addressToSelect));
      setAddressPreSelectInited(true);
    }
  }, [addressToSelect, addressPreSelectInited, handleSelectOrDeselect, formatAddress]);

  const getSelectedChips = useMemo(() => {
    let buildingChips = [];
    if (selectedBuildings && selectedBuildings.length) {
      buildingChips = selectedBuildings.map((building) => {
        let icon = building.isAddress ? <AddressIcon /> : <BuildingIcon />;

        if (building.linkedToCentralCompany) {
          icon = (
            <>
              {building.isAddress ? (
                <AddressIcon className="ms-2" />
              ) : (
                <BuildingIcon className="ms-2" />
              )}
              <ValidCompanyIcon className="ms-1" />
            </>
          );
        }

        return (
          <Chip
            icon={icon}
            key={building.id}
            label={building.display}
            onDelete={() => handleSelectOrDeselect(building)}
          />
        );
      });
    }

    return buildingChips && buildingChips.length > 0 && [buildingChips];
  }, [handleSelectOrDeselect, selectedBuildings]);

  const { mutateAsync: createBuildingAsync } = trpc.building.create.useMutation();

  const handleCreateBuilding = async (building) => {
    const request = {
      ...building,
      administrators: building.administrators.map((a) => a.value),
      tags: building.buildingTags.map((tag) => tag.value),
    };
    createBuildingAsync(request, {
      onSuccess: (data) => {
        handleSelectOrDeselect(formatBuilding(data));
        setIsAddingNewBuilding(false);
      },
    });
  };

  const handleAddAddress = (address) => {
    handleSelectOrDeselect(formatAddress(address));
    setIsAddingNewAddress(false);
  };

  const handleBlur = () => {
    if (isInside) {
      return;
    }
    onBlur();
    setOutsideAlerterActive(false);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Tab') {
      collapseSelect();
    }
  };

  const getHelperText = () => {
    if (errorText != null && errorText.length > 0) {
      return errorText;
    }

    if (isRequired && !selectedBuildings.length) {
      return t('errors:fieldIsRequired');
    }

    return null;
  };

  const textFieldClasses = classNames({
    'chip-textfield': true,
    'chip-textfield-flex-wrap': !selectSingle,
  });

  return (
    <ClickAwayListener onClickAway={() => collapseSelect()}>
      <div className="custom__selector">
        <Box
          sx={{
            alignItems: 'flex-end',
            display: 'flex',
          }}
        >
          {((alwaysOpen && !isAddingNewAddress && !isAddingNewBuilding) || !alwaysOpen) && (
            <TextField
              className={textFieldClasses}
              size="small"
              id="input-with-sx"
              label={`${label || t('common:search.label')}${isRequired ? ' *' : ''}`}
              variant="standard"
              onChange={({ target }) => setQuery(target.value.toLowerCase())}
              value={query}
              onFocus={() => {
                setIsSelectOpen(true);
                setOutsideAlerterActive(true);
              }}
              autoComplete="off"
              fullWidth
              error={
                (isRequired && !selectedBuildings.length) ||
                (errorText != null && errorText.length > 0)
              }
              helperText={getHelperText()}
              InputProps={{
                startAdornment: getSelectedChips,
              }}
              ref={anchorRef}
              onBlur={() => handleBlur()}
              onKeyDown={handleKeyDown}
            />
          )}
        </Box>
        {alwaysOpen ? (
          <BuildingSelectorList
            selectorMenuRef={selectorMenuRef}
            isAddingNewAddress={isAddingNewAddress}
            filteredBuildings={filteredBuildings}
            suggestions={suggestions}
            handleSelectOrDeselect={handleSelectOrDeselect}
            selectOnly={selectOnly}
            hideAddBuilding={hideAddBuilding}
            isAddingNewBuilding={isAddingNewBuilding}
            setIsAddingNewBuilding={setIsAddingNewBuilding}
            hideAddAddress={hideAddAddress}
            setIsAddingNewAddress={setIsAddingNewAddress}
            handleCreateBuilding={handleCreateBuilding}
            handleAddAddress={handleAddAddress}
            showSuggestions={showSuggestions}
            prefillAddress={prefillAddress}
            showInactiveSwitch={showInactiveSwitch}
          />
        ) : (
          <PopperUnstyled
            disablePortal={disablePortal}
            container={popperContainer?.current}
            open={isSelectOpen}
            anchorEl={anchorRef?.current}
            className="custom__selector-wrap"
            placement="bottom-start"
          >
            <BuildingSelectorList
              selectorMenuRef={selectorMenuRef}
              isAddingNewAddress={isAddingNewAddress}
              filteredBuildings={filteredBuildings}
              suggestions={suggestions}
              handleSelectOrDeselect={handleSelectOrDeselect}
              selectOnly={selectOnly}
              hideAddBuilding={hideAddBuilding}
              isAddingNewBuilding={isAddingNewBuilding}
              setIsAddingNewBuilding={setIsAddingNewBuilding}
              hideAddAddress={hideAddAddress}
              setIsAddingNewAddress={setIsAddingNewAddress}
              handleCreateBuilding={handleCreateBuilding}
              handleAddAddress={handleAddAddress}
              showSuggestions={showSuggestions}
              prefillAddress={prefillAddress}
              showInactiveSwitch={showInactiveSwitch}
            />
          </PopperUnstyled>
        )}
      </div>
    </ClickAwayListener>
  );
};

SelectBuilding.propTypes = {
  addressToSelect: PropTypes.shape({
    addressLine: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  alwaysOpen: PropTypes.bool,
  buildingIdsToSelect: PropTypes.arrayOf(PropTypes.string),
  buildingsToExclude: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  centralCompanyId: PropTypes.string,
  disablePortal: PropTypes.bool,
  errorText: PropTypes.string,
  hideAddAddress: PropTypes.bool,
  hideAddBuilding: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onBuildingSelectChanged: PropTypes.func.isRequired,
  popperContainer: ReferencePropType,
  prefillAddress: AddressPropTypes,
  selectOnly: PropTypes.bool,
  selectSingle: PropTypes.bool,
  showInactiveSwitch: PropTypes.bool,
  showSuggestions: PropTypes.bool,
};

SelectBuilding.defaultProps = {
  addressToSelect: null,
  alwaysOpen: false,
  buildingIdsToSelect: [],
  buildingsToExclude: [],
  centralCompanyId: null,
  disablePortal: true,
  errorText: null,
  hideAddAddress: false,
  hideAddBuilding: false,
  isRequired: false,
  label: '',
  onBlur: () => {},
  popperContainer: null,
  prefillAddress: null,
  selectOnly: false,
  selectSingle: false,
  showInactiveSwitch: false,
  showSuggestions: false,
};

export default SelectBuilding;
