import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  Tooltip,
} from '@mui/joy';
import { useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { AddEditBuildingFormikValues } from './AddEditBuildingFormConfig';

import { trpc } from '@/config/trpc';

import FormikAddressForm from '../../../shared/formik/FormikAddressForm';
import FormikVatInput from '@/shared/formik/FormikVatInput';
import FormikYearPicker from '@/shared/formik/FormikYearPicker';
import { InlineSpinner } from '@/shared/icons/Icons';
import TeamUsersAutocomplete from '@/shared/components/2.0/autocomplete/TeamUsersAutocomplete';

type Props = {
  isEdit?: boolean;
  minified?: boolean;
};

const AddEditBuildingFormBody: React.FC<Props> = ({ isEdit = false, minified = false }) => {
  const prevCompanyName = useRef<string | null>(null);

  const { t } = useTranslation();
  const { setFieldValue, setFieldTouched, values, touched, errors } =
    useFormikContext<AddEditBuildingFormikValues>();

  const { data: buildingTags, isLoading: buildingTagsAreLoading } =
    trpc.building.tag.list.useQuery();

  const selectedUsers = useMemo(
    () => values.administrators.map(({ value }) => value),
    [values.administrators],
  );

  const hasError = (key: keyof typeof values) => !!touched[key] && !!errors[key];

  return (
    <div className="flex flex-col gap-4">
      {!isEdit && (
        <FormControl>
          <FormLabel>{t('tags')}</FormLabel>
          <Autocomplete
            multiple
            value={values.buildingTags}
            options={buildingTags || []}
            onChange={(_, options) => setFieldValue('buildingTags', options)}
            startDecorator={buildingTagsAreLoading ? <InlineSpinner /> : null}
          />
        </FormControl>
      )}
      <Tooltip
        title={
          values.homeownerAssociationId
            ? t('_building.forms.addEdit.vatNumberDisabledTooltip')
            : undefined
        }
        arrow
      >
        <div>
          <FormikVatInput
            disabled={!!values.homeownerAssociationId}
            name="vatNumber"
            callback={({ companyName }) => {
              if (!values.name && companyName && companyName !== prevCompanyName.current) {
                setFieldValue('name', companyName);
              }

              prevCompanyName.current = companyName;
            }}
          />
        </div>
      </Tooltip>
      <FormControl error={hasError('name')}>
        <FormLabel required>{t('name')}</FormLabel>
        <Input
          value={values.name || ''}
          onChange={(e) => {
            setFieldTouched('name');
            setFieldValue('name', e.target.value);
          }}
          onBlur={() => setFieldTouched('name')}
        />
        {hasError('name') && (
          <FormHelperText className="px-1 text-xs">{errors.name}</FormHelperText>
        )}
      </FormControl>
      {isEdit && (
        <FormControl>
          <Checkbox
            label={t('active')}
            checked={values.isActive}
            onChange={(e) => setFieldValue('isActive', e.target.checked)}
          />
        </FormControl>
      )}
      {isEdit && <FormikYearPicker name="buildingYear" label={t('buildingYear')} />}
      <FormikAddressForm />
      {!minified && (
        <FormControl>
          <FormLabel>{t('administrators')}</FormLabel>
          <TeamUsersAutocomplete
            multiple
            selected={selectedUsers}
            onChange={(value) => setFieldValue('administrators', value)}
          />
        </FormControl>
      )}
      <FormControl>
        <FormLabel>{t('info')}</FormLabel>
        <Textarea
          value={values.info || ''}
          onChange={(e) => setFieldValue('info', e.target.value)}
        />
      </FormControl>
    </div>
  );
};

export default AddEditBuildingFormBody;
