import { Button, Chip, ChipDelete, FormHelperText } from '@mui/joy';
import { ClickAwayListener, Popper as PopperUnstyled } from '@mui/base';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AdministratorIcon } from '@/shared/icons/Icons';
import { AppContext } from '@/shared/context/context';
import AvatarWithColor from '../AvatarWithColor';
import TextField from '../input/TextField';
import Tooltip from '../Tooltip';
import { trpc } from '@/config/trpc';

const ITEM_LIMIT = 5;

type Props = {
  callback: (teamUserIds: string[] | string) => void;
  caseId?: string;
  disabled?: boolean;
  isRequired?: boolean;
  isTouched?: boolean;
  label?: string;
  selectSingle?: boolean;
  teamUserIdsToExclude?: string[];
  usersToSelect?: string[];
};

type SelectedUser = {
  isAdmin: boolean;
  name: string;
  team: string;
  teamId: string;
  teamUserId: string;
};

const SelectTeamUser: React.FC<Props> = ({
  callback,
  caseId,
  disabled,
  isRequired,
  isTouched,
  label,
  selectSingle,
  teamUserIdsToExclude = [],
  usersToSelect = [],
  ...rest
}) => {
  const { t } = useTranslation();
  const { currentTeam } = useContext(AppContext);

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [query, setQuery] = useState('');

  const [selectedTeamUsers, setSelectedTeamUsers] = useState<SelectedUser[]>([]);
  const [selectedTeamUsersInited, setSelectedTeamUsersInited] = useState(false);

  const { data: currentTeamUsers, isLoading: currentTeamUsersAreLoading } =
    trpc.team.user.list.useQuery(undefined, {
      enabled: !caseId,
    });

  const { data: caseTeamUsers, isLoading: caseTeamUsersAreLoading } =
    trpc.case.teamUsersWithAccess.useQuery(caseId ?? '', {
      enabled: !!caseId,
    });

  const collapseSelect = () => {
    setIsSelectOpen(false);
  };

  const formattedTeamUsers = useMemo(() => {
    if (currentTeamUsersAreLoading || currentTeamUsers == null || !currentTeam) return [];

    return [
      {
        team: currentTeam.name ?? '',
        teamId: currentTeam.id ?? '',
        users: currentTeamUsers.map((u) => ({
          isAdmin: false,
          name: u.name ?? '',
          teamUserId: u.id ?? '',
        })),
      },
    ];
  }, [currentTeam, currentTeamUsers, currentTeamUsersAreLoading]);

  const formattedCaseTeamUsers = useMemo(() => {
    if (caseTeamUsersAreLoading || caseTeamUsers == null) return [];

    const grouped = groupBy(caseTeamUsers, (x) => x.teamId);

    return Object.keys(grouped).map((key) => {
      const users = grouped[key];
      const first = users[0];

      return {
        team: first.team ?? '',
        teamId: first.teamId ?? '',
        users: users.map((u) => ({
          isAdmin: u.isAdmin,
          name: u.username ?? '',
          teamUserId: u.teamUserId ?? '',
        })),
      };
    });
  }, [caseTeamUsers, caseTeamUsersAreLoading]);

  const teamUsers = caseId ? formattedCaseTeamUsers : formattedTeamUsers;

  const handleSelect = (
    team: string,
    teamId: string,
    user: { isAdmin: boolean; teamUserId: string; name: string },
  ) => {
    const { name, teamUserId, isAdmin } = user;
    const currentlySelected = selectedTeamUsers;
    const updated = {
      isAdmin,
      name,
      team,
      teamId,
      teamUserId,
    };

    let updatedList = null;
    if (!selectSingle) {
      updatedList = [...currentlySelected, updated];
    } else {
      updatedList = [updated];
    }
    setSelectedTeamUsers(updatedList);
    let callbackValue;
    if (selectSingle) {
      callbackValue = updatedList[0]?.teamUserId;
    } else {
      callbackValue = updatedList.map((u) => u.teamUserId);
    }
    callback(callbackValue);
    setQuery('');
  };

  const getSelectedChips = () => {
    if (!selectedTeamUsers || selectedTeamUsers.length === 0) {
      return null;
    }

    const handleDeselect = (teamUserId: string) => {
      const currentlySelected = selectedTeamUsers;
      const updated = currentlySelected.filter((s) => s.teamUserId !== teamUserId);

      setSelectedTeamUsers(updated);
      callback(updated.map((u) => u.teamUserId));
    };

    return selectedTeamUsers.map((u) => {
      const { team, teamUserId, name, isAdmin } = u;
      const display = isAdmin ? (
        <div className="flex items-center">
          <AvatarWithColor name={name} size="sm" />
          <FontAwesomeIcon icon={['fad', 'user-crown']} className="ms-1" />
        </div>
      ) : (
        <AvatarWithColor name={name} size="sm" />
      );

      return (
        <Tooltip key={teamUserId} disableFocusListener title={`${name} (${team})`}>
          <Chip
            variant="outlined"
            className="mr-1"
            startDecorator={display}
            endDecorator={
              <ChipDelete disabled={disabled} onClick={() => handleDeselect(teamUserId)} />
            }
          />
        </Tooltip>
      );
    });
  };

  useEffect(() => {
    if (
      selectedTeamUsersInited ||
      usersToSelect == null ||
      usersToSelect.length === 0 ||
      teamUsers == null ||
      teamUsers.length === 0
    ) {
      return;
    }

    let addedTeamUsers: SelectedUser[] = [];
    teamUsers.forEach((team) =>
      team.users
        .filter((tu) => usersToSelect.includes(tu.teamUserId))
        .forEach((tu) => {
          const { name, teamUserId, isAdmin } = tu;
          addedTeamUsers = [
            ...addedTeamUsers,
            {
              isAdmin,
              name,
              team: team.team,
              teamId: team.teamId,
              teamUserId,
            },
          ];
        }),
    );
    setSelectedTeamUsers(addedTeamUsers);
    setSelectedTeamUsersInited(true);
  }, [selectedTeamUsersInited, teamUsers, usersToSelect]);

  const anchorRef = useRef();

  const textFieldClasses = classNames({
    'chip-textfield': true,
    'chip-textfield-flex-wrap': !selectSingle,
  });
  return (
    <ClickAwayListener onClickAway={collapseSelect}>
      <div className="custom__selector" {...rest}>
        <div className="flex grow-0 items-end">
          <TextField
            label={label ?? ''}
            isRequired={isRequired}
            inputClassName={textFieldClasses}
            value={query}
            // @ts-ignore
            onChange={({ target }) => setQuery(target.value)}
            onFocus={() => setIsSelectOpen(true)}
            autoComplete="off"
            disabled={disabled}
            error={isTouched && isRequired && !selectedTeamUsers.length}
            helperText={
              isTouched && isRequired && !selectedTeamUsers.length
                ? t('errors:fieldIsRequired')
                : ''
            }
            startDecorator={getSelectedChips()}
            textfieldRef={anchorRef}
          />
        </div>
        <PopperUnstyled
          open={isSelectOpen}
          className="custom__selector-wrap"
          anchorEl={anchorRef?.current}
          placement="bottom-start"
        >
          <div className="max-h-[40vh] overflow-y-auto rounded-xl bg-white p-4 shadow-xl">
            {teamUsers
              .filter(
                (tu) =>
                  tu.team.toLowerCase().includes(query.toLowerCase()) ||
                  tu.users.some((u) => u.name.toLowerCase().includes(query.toLowerCase())),
              )
              .filter((tu) =>
                tu.users.some((u) => !selectedTeamUsers.some((s) => s.teamUserId === u.teamUserId)),
              )
              .map((tu) => {
                const { team, users, teamId } = tu;
                const filteredUsers = users
                  .filter((u) => !teamUserIdsToExclude.some((id) => id === u.teamUserId))

                  .filter((u) => !selectedTeamUsers.some((s) => s.teamUserId === u.teamUserId))
                  .filter(
                    (u) =>
                      team.toLowerCase().includes(query.toLowerCase()) ||
                      u.name.toLowerCase().includes(query.toLowerCase()),
                  );

                return (
                  <div key={teamId} className="flex flex-col space-y-4">
                    <h5 className="text-primary">{team}</h5>
                    <div className="flex flex-col space-y-2">
                      {filteredUsers
                        .filter((_, i) => i < ITEM_LIMIT)
                        .map((u) => {
                          const { name, teamUserId, isAdmin } = u;
                          return (
                            <Button
                              className="w-full justify-start"
                              variant="plain"
                              color="neutral"
                              startDecorator={
                                <>
                                  <AvatarWithColor name={name} size="sm" className="mr-1" />
                                  {isAdmin && <AdministratorIcon />}
                                </>
                              }
                              key={teamUserId}
                              onClick={() => handleSelect(team, teamId, u)}
                            >
                              {name}
                            </Button>
                          );
                        })}
                      {filteredUsers.length > ITEM_LIMIT && (
                        <FormHelperText>{t('moreResultsAreHiddenRefineFilter')}</FormHelperText>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </PopperUnstyled>
      </div>
    </ClickAwayListener>
  );
};

export default SelectTeamUser;
