import i18next from 'i18next';
import { lowerFirst } from 'lodash';

import { Enum, UseAutocompleteEnumArgs } from './autocomplete.types';

type GenerateOptionsArgs<TEnum extends Enum> = Pick<
  UseAutocompleteEnumArgs<TEnum>,
  'includeValue' | 'srcEnum' | 'translationKey'
>;

/* eslint-disable import/prefer-default-export */
export const generateOptionsFromEnum = <TEnum extends Enum>({
  includeValue,
  srcEnum,
  translationKey,
}: GenerateOptionsArgs<TEnum>) => {
  const { t } = i18next;

  return Object.values(srcEnum)
    .filter(
      (value) =>
        value !== 'Unspecified' && (!includeValue || includeValue(value as TEnum[keyof TEnum])),
    )
    .map((value) => {
      const subKey = lowerFirst(value.toString());

      return {
        label: translationKey ? t(`${translationKey}.${subKey}`) : t(subKey),
        value: value as Exclude<TEnum[keyof TEnum], 'Unspecified'>,
      };
    });
};
