import { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { CaseContext } from '@/containers/Cases/CaseContext';
import ChangeAccessibilityOption from '../ChangeAccessabilityOption';
import ChangeCaseStatusOption from '../ChangeCaseStatusOption';
import RemarkCaseEntityOption from '../RemarkCaseEntityOption';
import TaskCreator from '../../tasks/TaskCreator';

const propTypes = {
  allowRemark: PropTypes.bool,
  canEditAccess: PropTypes.bool,
  entity: PropTypes.shape({
    id: PropTypes.string,
    showOnClientPage: PropTypes.bool,
  }),
  selectedCase: PropTypes.shape({}),
  shouldNotBeShownOnPublicPage: PropTypes.bool,
};

const FormikCaseEntityOptions = ({
  allowRemark = false,
  entity = null,
  canEditAccess = true,
  selectedCase = null,
  shouldNotBeShownOnPublicPage = false,
}) => {
  const { setFieldValue } = useFormikContext();
  const { currentCase } = useContext(CaseContext);

  const handleTaskChange = useCallback(
    (task) => {
      if (!task.teamTaskTypeId && !task.systemTaskType && !task.description) {
        setFieldValue('tasks', []);
        return;
      }

      setFieldValue('tasks', [task]);
    },
    [setFieldValue],
  );

  const updateCaseStatus = useCallback(
    (data) => {
      const { id, value } = data;
      if (!id && !value) {
        setFieldValue('caseStatus', null);
        return;
      }

      const status = {
        caseStatusId: value == null ? id : null,
        status: value,
      };
      setFieldValue('caseStatus', status);
    },
    [setFieldValue],
  );

  const handleUpdateTeamAccessibility = useCallback(
    (teams) => {
      if (!teams.some((team) => team.hasAccess === false)) {
        setFieldValue('teamsWithAccess', null);
      } else {
        setFieldValue(
          'teamsWithAccess',
          teams.filter((team) => team.hasAccess).map((team) => team.teamId),
        );
      }
    },
    [setFieldValue],
  );

  const handleUpdateShowOnClientPage = useCallback(
    (show) => {
      setFieldValue('showOnClientPage', show);
    },
    [setFieldValue],
  );

  const cancel = (name) => {
    setFieldValue(name, null);
  };

  return (
    <div className="mt-2">
      {allowRemark && <RemarkCaseEntityOption name="remark" />}
      <TaskCreator
        callback={handleTaskChange}
        linkBtn
        onCancel={() => cancel('task')}
        currentCase={currentCase}
      />
      <ChangeCaseStatusOption callback={updateCaseStatus} onCancel={() => cancel('caseStatus')} />
      {canEditAccess && (
        <ChangeAccessibilityOption
          handleUpdateTeamAccessibility={handleUpdateTeamAccessibility}
          handleUpdateShowOnClientPage={handleUpdateShowOnClientPage}
          shouldNotBeShownOnPublicPage={shouldNotBeShownOnPublicPage}
          entity={entity}
          selectedCase={selectedCase}
          onCancel={() => {
            cancel('teamsWithAccess');
            cancel('showOnClientPage');
          }}
        />
      )}
    </div>
  );
};
FormikCaseEntityOptions.propTypes = propTypes;

export default FormikCaseEntityOptions;
