import { Chip, TextField } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { arraysAreEqual, sentencize } from '../../../../shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import CaseRelationPropTypes from '../../../../shared/prop-types/CaseRelationPropTypes';

import AvatarWithColor from '@/shared/components/2.0/AvatarWithColor';
import CustomTextFieldAdornment from '../../../../shared/components/CustomTextFieldAdorment';
import KpcTooltip from '../../../../shared/components/Tooltips/KpcTooltip';
import SelectTeamUser from '../../../../shared/components/select/SelectTeamUser';
import TextCheckbox from '../../../../shared/components/checkboxes/TextCheckbox';

const propTypes = {
  administrators: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      relationId: PropTypes.string,
      teamUserId: PropTypes.string,
    }),
  ),
  canEdit: PropTypes.bool,
  canManageOwnTeam: PropTypes.bool,
  caseReference: PropTypes.string.isRequired,
  currentTeamIsOwner: PropTypes.bool.isRequired,
  onSaveCallback: PropTypes.func.isRequired,
  relation: CaseRelationPropTypes.isRequired,
};

const MAX_REFERENCE_LENGTH = 70;


const CaseRelationCustomCellDetails = ({
  relation,
  caseReference = '',
  currentTeamIsOwner,
  onSaveCallback,
  canEdit = false,
  canManageOwnTeam = false,
  administrators = [],
}) => {
  const { relationReference, teamId, hasAccess, id: relationId, remark } = relation;

  const [showReferenceLengthWarning, setShowReferenceLengthWarning] = useState(false);
  const [referenceValue, updateReferenceValue] = useState(
    relationReference == null || relationReference.length === 0 ? caseReference : relationReference,
  );
  const [isReferenceDirty, setIsReferenceDirty] = useState(false);

  const [remarkValue, updateRemarkValue] = useState(remark ?? '');
  const [isRemarkDirty, setIsRemarkDirty] = useState(false);

  const {
    ui: { currentTeamId },
  } = useContext(AppContext);
  const { t } = useTranslation();

  const isOwnerOrOwnTeam = useMemo(
    () => currentTeamIsOwner || teamId === currentTeamId,
    [currentTeamIsOwner, teamId, currentTeamId],
  );

  const administratorTeamUsers = useMemo(
    () => administrators?.map((admin) => admin.teamUserId),
    [administrators],
  );

  const handleReferenceChange = ({ target }) => {
    if (!isOwnerOrOwnTeam) {
      return;
    }
    const { value } = target;
    if (value?.length > MAX_REFERENCE_LENGTH) {
      setShowReferenceLengthWarning(true);
    } else {
      setShowReferenceLengthWarning(false);
    }
    updateReferenceValue(value);
    if (value === relationReference) {
      setIsReferenceDirty(false);
      return;
    }
    setIsReferenceDirty(true);
  };

  const handleCancelReferenceChange = () => {
    updateReferenceValue(relationReference ?? '');
    setIsReferenceDirty(false);
    setShowReferenceLengthWarning(false);
  };

  const handleRemarkChange = ({ target }) => {
    if (!isOwnerOrOwnTeam) {
      return;
    }
    const { value } = target;
    updateRemarkValue(value);
    if (value === remark) {
      setIsRemarkDirty(false);
      return;
    }
    setIsRemarkDirty(true);
  };

  const handleCancelRemarkChange = () => {
    updateRemarkValue(remark ?? '');
    setIsRemarkDirty(false);
  };

  const resetDirty = useCallback(() => {
    setIsReferenceDirty(false);
    setIsRemarkDirty(false);
  }, []);

  const handleSave = (access, admins) => {
    onSaveCallback(relation.id, {
      administrators: admins,
      hasAccess: access,
      reference: referenceValue,
      remark: remarkValue,
    });
    resetDirty();
  };

  const handleAdminsUpdate = (newAdmins) => {
    if (!arraysAreEqual(newAdmins ?? [], administratorTeamUsers ?? [])) {
      handleSave(hasAccess, newAdmins);
    }
  };

  const getOtherTeamAdminChips = useMemo(
    () =>
      administrators.map((u) => {
        const { name } = u;
        return (
          <KpcTooltip disableFocusListener title={name}>
            <Chip avatar={<AvatarWithColor name={name} size="lg" />} key={name} className="me-1" />
          </KpcTooltip>
        );
      }),
    [administrators],
  );

  return (
    <div>
      <TextField
        label={t('reference')}
        value={referenceValue}
        variant="standard"
        disabled={!isOwnerOrOwnTeam || !canEdit}
        fullWidth
        className="mb-4"
        error={showReferenceLengthWarning}
        helperText={
          showReferenceLengthWarning
            ? sentencize(
                t('errors:fieldXmaxYcharacters', {
                  field: t('reference'),
                  max: MAX_REFERENCE_LENGTH,
                }),
              )
            : ''
        }
        onChange={handleReferenceChange}
        InputProps={{
          endAdornment: (
            <CustomTextFieldAdornment
              isDirty={isReferenceDirty}
              canSave={!showReferenceLengthWarning}
              onCancel={handleCancelReferenceChange}
              onSave={() => handleSave(hasAccess, administratorTeamUsers)}
            />
          ),
        }}
      />
      <TextField
        label={t('remark')}
        variant="standard"
        fullWidth
        className="mb-4"
        value={remarkValue}
        disabled={!canEdit}
        onChange={handleRemarkChange}
        InputProps={{
          endAdornment: (
            <CustomTextFieldAdornment
              isDirty={isRemarkDirty}
              onCancel={handleCancelRemarkChange}
              onSave={() => handleSave(hasAccess, administratorTeamUsers)}
            />
          ),
        }}
      />
      <TextCheckbox
        id={relationId}
        yesText={t('hasAccessToThisCase')}
        noText={t('hasNoAccessToThisCase')}
        isChecked={hasAccess}
        hidden={teamId == null}
        customClass="mb-4"
        disabled={
          !currentTeamIsOwner ||
          (currentTeamIsOwner === true && teamId === currentTeamId && !canManageOwnTeam) ||
          !canEdit
        }
        disabledInfo={
          !currentTeamIsOwner
            ? t('onlyCaseOwnerCanChangeAccessibility')
            : t('cannotChangeTheAccessibilityOfYourOwnTeam')
        }
        paragraphProps={{
          style: {
            margin: '-3px',
          },
        }}
        callback={(newVal) => handleSave(newVal, administratorTeamUsers)}
      />
      {hasAccess === true && teamId === currentTeamId && (
        <SelectTeamUser
          usersToSelect={administratorTeamUsers}
          label={t('administrators')}
          callback={handleAdminsUpdate}
          className="mb-4"
          disabled={teamId !== currentTeamId || !canEdit}
        />
      )}
      {hasAccess === true && teamId !== currentTeamId && (
        <TextField
          size="small"
          id="input-with-sx"
          label={t('administrators')}
          variant="standard"
          autoComplete="off"
          fullWidth
          disabled
          InputProps={{
            startAdornment: getOtherTeamAdminChips,
          }}
        />
      )}
    </div>
  );
};

CaseRelationCustomCellDetails.propTypes = propTypes;

export default CaseRelationCustomCellDetails;
