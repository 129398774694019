import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ContractorProps } from '../AddContractorForm';
import useDayjs from '@/shared/hooks/useDayjs';

const Card: FC<{ children: React.ReactNode; title: string }> = ({ title, children }) => (
  <div className="flex-col rounded-3xl border border-gray-300 px-4 py-4">
    <p className="bold">{title}</p>
    <div className="h-[100px] overflow-y-auto pl-4 pt-2 text-sm">{children}</div>
  </div>
);

type Props = {
  contractor: ContractorProps;
  skillIds: string[];
};

const ContractorDetails: FC<Props> = ({ contractor, skillIds }) => {
  const { t } = useTranslation();
  const { dayjs } = useDayjs();

  const { skills, absences, totalOpenCases, capacity, isOverGlobalCapacity } = contractor;

  const filteredSkills = useMemo(
    () => skills.filter(({ skillDefinitionId }) => skillIds.includes(skillDefinitionId)),
    [skillIds, skills],
  );

  const absenceDates = useMemo(() => {
    const now = dayjs();
    return absences
      .map(({ fromDate, untilDate }) => ({
        fromDate: dayjs(fromDate),
        untilDate: dayjs(untilDate),
      }))
      .filter(
        ({ fromDate, untilDate }) =>
          now.isBefore(fromDate) || now.isBetween(fromDate, untilDate, 'day', '[]'),
      )
      .sort((a, b) => (a.fromDate.isBefore(b.fromDate) ? -1 : 1));
  }, [absences, dayjs]).slice(0, 3);

  return (
    <div className="mt-4 grid grid-cols-3 gap-4">
      <Card title={t('capacity')}>
        <ul className="list-disc">
          <li
            key="global"
            className={classNames({
              'text-green-400': !isOverGlobalCapacity,
              'text-red-400': isOverGlobalCapacity,
            })}
          >
            {`${totalOpenCases}/${capacity}`}
          </li>
          {filteredSkills.map((skill) => (
            <li
              key={skill.label}
              className={classNames({
                'text-green-400': !skill.capacityExceeded,
                'text-red-400': skill.capacityExceeded,
              })}
            >
              {`${totalOpenCases}/${skill.capacity}`}
            </li>
          ))}
        </ul>
      </Card>
      <Card title={t('skills')}>
        {skills.length <= 5 && (
          <ul className="list-disc">
            {skills.map((skill) => (
              <li key={skill.skillDefinitionId}>{skill.label}</li>
            ))}
          </ul>
        )}
        {skills.length > 5 && (
          <div className="grid grid-cols-2">
            <ul className="list-disc">
              {skills.slice(0, skills.length / 2).map((skill) => (
                <li key={skill.skillDefinitionId}>{skill.label}</li>
              ))}
            </ul>
            <ul className="list-disc">
              {skills.slice(skills.length / 2, skills.length).map((skill) => (
                <li key={skill.skillDefinitionId}>{skill.label}</li>
              ))}
            </ul>
          </div>
        )}
      </Card>
      <Card title={t('absences')}>
        {absenceDates.length > 0 && (
          <>
            <p className="text-red-400">{t('_contractor.assign.absentFrom')}:</p>
            <ul className="list-disc">
              {absenceDates.map(({ fromDate, untilDate }, index) => (
                <li className="text-red-400" key={index}>
                  {`${fromDate.format('DD/MM/YYYY')} - ${untilDate.format('DD/MM/YYYY')}`}
                </li>
              ))}
            </ul>
          </>
        )}
        {absenceDates.length === 0 && (
          <p className="text-green-400">{t('_contractor.assign.noPlannedAbsences')}</p>
        )}
      </Card>
    </div>
  );
};

export default ContractorDetails;
