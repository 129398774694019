import { memo } from 'react';
import { Tooltip } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { Priority } from '~/common/enums';

import { PriorityHighIcon, PriorityLowIcon, PriorityNormalIcon } from '../../../icons/Icons';

const icons = {
  [Priority.High]: <PriorityHighIcon />,
  [Priority.Low]: <PriorityLowIcon />,
  [Priority.Normal]: <PriorityNormalIcon />,
};

type Props = {
  value?: Priority | null;
};

const PriorityIconCell: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  if (!value) return '';

  return (
    <Tooltip arrow title={`${t('priority')}: ${t(value.toLowerCase())}`}>
      <span>{icons[value]}</span>
    </Tooltip>
  );
};

export default memo(PriorityIconCell);
