import { Autocomplete, Button, IconButton, Input } from '@mui/joy';
import { getIn, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { BilledStatus, DivisionType, InboundHoaInvoiceVatCode, Unity } from '~/common/enums';
import {
  ContractOption,
  ContractPerformanceOption,
  HomeownerAssociationOption,
} from '@/types/trpc/hoa';
import { PerformanceFormFields } from './usePerformanceFormikConfig';

import {
  useAutocompleteEnum,
  useAutocompleteOptions,
  useBookYearAutocomplete,
  useHoaAccountAutocomplete,
} from '@/shared/hooks/autocomplete';
import useToggle, { UseToggle } from '@/shared/hooks/UseToggle';
import { trpc } from '@/config/trpc';

import {
  BuildingIcon,
  CancelIcon,
  CashRegisterIcon,
  DivisionKeyIcon,
  FileSignatureIcon,
  IndividualDivisionIcon,
  InlineSpinner,
  ReceiptIcon,
  ValidIcon,
} from '@/shared/icons/Icons';
import CurrencyFormatAdapter from '@/shared/components/2.0/adapters/CurrencyFormatAdapter';
import FormField from '@/shared/components/2.0/input/FormField';
import FormikDatePicker from '@/shared/components/2.0/formik/FormikDatePicker';
import IndividualDivisionModal from '@/containers/Finance/Shared/modals/IndividualDivisionModal';
import KpcTooltip from '@/shared/components/Tooltips/KpcTooltip';
import NumericFormatAdapter from '@/shared/components/2.0/adapters/NumericFormatAdapter';

type Props<T> = {
  isEdit: boolean;
  isMutating: boolean;
  toggle: UseToggle<T>;
};

const AddEditPerformanceFormBody = <T,>({ isEdit, isMutating, toggle }: Props<T>) => {
  const { initialValues, setFieldValue, setFieldTouched, values, errors, touched } =
    useFormikContext<PerformanceFormFields>();
  const { t } = useTranslation();

  const individualDivisionToggle = useToggle();

  const contractOptionsRes = trpc.hoa.contract.autocomplete.useQuery(
    { homeownerAssociationId: values.homeownerAssociationId as string },
    { enabled: !!values.homeownerAssociationId },
  );

  const contractPerformanceOptionsRes = trpc.hoa.contract.performance.autocomplete.useQuery(
    {
      contractId: values.contractId as string,
    },
    { enabled: !!values.contractId },
  );
  const hoaOptionsRes = trpc.hoa.autocomplete.useQuery();
  const hoaDivisionKeyOptionsRes = trpc.hoa.divisionKey.autocomplete.useQuery(
    {
      allowDeferredTypes: false,
      id: values.homeownerAssociationId as string,
    },
    { enabled: !!values.homeownerAssociationId },
  );

  const isSameContractPerformance =
    values.contractId === initialValues.contractId &&
    values.contractPerformanceId === initialValues.contractPerformanceId &&
    values.description === initialValues.description &&
    values.total === initialValues.total &&
    values.unity === initialValues.unity &&
    values.unitPrice === initialValues.unitPrice;

  const resetFields = () => {
    setFieldValue('unitPrice', null);
    setFieldValue('unity', Unity.Piece);
    setFieldValue('vat', InboundHoaInvoiceVatCode.PurchaseServices21);
    setFieldValue('description', null);
  };

  const handleContractSelection = (option: ContractOption) => {
    setFieldValue('contractId', option.value);

    if (!isSameContractPerformance) {
      setFieldValue('contractPerformanceId', null);
      resetFields();
    }
  };

  const handleContractPerformanceSelection = (option: ContractPerformanceOption) => {
    setFieldValue('contractPerformanceId', option.value);

    if (!option.data.teamPerformance) return;

    const { currentIndexNumber, startIndexNumber, teamPerformance } = option.data;

    const unitPrice = (() => {
      if (!currentIndexNumber || !startIndexNumber) return teamPerformance.defaultPrice;

      return (teamPerformance.defaultPrice / startIndexNumber) * currentIndexNumber;
    })();

    setFieldValue('unitPrice', unitPrice);
    setFieldValue('unity', teamPerformance.unity);
    setFieldValue('vat', teamPerformance.vat);
    setFieldValue('description', teamPerformance.description);
  };

  const handleHoaSelection = (option: HomeownerAssociationOption) => {
    const selectedId = option.value;

    if (selectedId !== values.homeownerAssociationId) {
      setFieldValue('divisionKeyId', null);
      setFieldValue('homeownerAssociationGeneralLedgerAccountId', null);
      setFieldValue('individualDivisions', []);
      setFieldValue('contractPerformanceId', null);
    }

    setFieldValue('homeownerAssociationId', selectedId);
  };

  const autocompleteProps = {
    bookYear: useBookYearAutocomplete({
      activeOnly: !isEdit,
      callback: (option) => setFieldValue('bookYearId', option.value),
      disableClearable: true,
      homeownerAssociationId: values.homeownerAssociationId,
      selected: values.bookYearId,
    }),
    contract: useAutocompleteOptions({
      ...contractOptionsRes,
      callback: handleContractSelection,
      disableClearable: true,
      selected: values.contractId,
    }),
    contractPerformance: useAutocompleteOptions({
      ...contractPerformanceOptionsRes,
      callback: handleContractPerformanceSelection,
      disableClearable: true,
      selected: values.contractPerformanceId,
    }),
    hoa: useAutocompleteOptions({
      ...hoaOptionsRes,
      callback: handleHoaSelection,
      disableClearable: true,
      selected: values.homeownerAssociationId,
    }),
    hoaAccount: useHoaAccountAutocomplete({
      callback: (option) =>
        setFieldValue('homeownerAssociationGeneralLedgerAccountId', option?.value || null),
      homeownerAssociationId: values.homeownerAssociationId,
      selected: values.homeownerAssociationGeneralLedgerAccountId,
    }),
    hoaDivisionKey: useAutocompleteOptions({
      ...hoaDivisionKeyOptionsRes,
      callback: (option) => setFieldValue('divisionKeyId', option?.value || null),
      selected: values.divisionKeyId,
    }),
    status: useAutocompleteEnum({
      callback: (option) => setFieldValue('status', option.value),
      disableClearable: true,
      selected: values.status,
      srcEnum: BilledStatus,
    }),
    unit: useAutocompleteEnum({
      callback: (option) => setFieldValue('unity', option.value),
      disableClearable: true,
      selected: values.unity,
      srcEnum: Unity,
    }),
    vat: useAutocompleteEnum({
      callback: (option) => setFieldValue('vat', option.value),
      disableClearable: true,
      selected: values.vat,
      srcEnum: InboundHoaInvoiceVatCode,
      translationKey: '_inboundHoaInvoiceVatCode',
    }),
  };

  const isIndividualDivisionValid = useMemo(
    () =>
      values.individualDivisions?.reduce(
        (acc, share) => acc + parseFloat(share.percentage ?? '0'),
        0,
      ) === 100,
    [values.individualDivisions],
  );

  const showIndividualDivisionWarning = useMemo(() => {
    if (
      !!values.divisionKeyId ||
      !values.individualDivisions?.length ||
      values.divisionType !== DivisionType.Amount
    ) {
      return false;
    }
    return sumBy(values.individualDivisions, (d) => d.amount) !== values.total;
  }, [values]);

  const handleBlur = (key: keyof typeof values) => setFieldTouched(key);

  const getError = (key: keyof PerformanceFormFields) =>
    getIn(touched, key) ? getIn(errors, key) : '';

  useEffect(() => {
    const newTotal = values.unitPrice ? values.amount * values.unitPrice : 0;

    if (newTotal !== values.total) {
      setFieldValue('total', values.unitPrice ? values.amount * values.unitPrice : 0);
    }
  }, [values.amount, values.total, values.unitPrice, setFieldValue]);

  return (
    <div className="flex flex-col gap-2">
      <FormField
        label={t('homeownerAssociation')}
        error={getError('homeownerAssociationId')}
        required
      >
        <Autocomplete
          {...autocompleteProps.hoa}
          disabled={autocompleteProps.hoa.disabled || isEdit}
          startDecorator={autocompleteProps.hoa.loading ? <InlineSpinner /> : <BuildingIcon />}
          onBlur={() => handleBlur('homeownerAssociationId')}
        />
      </FormField>
      <FormField label={t('bookyear')} error={getError('bookYearId')} required>
        <Autocomplete
          {...autocompleteProps.bookYear}
          startDecorator={autocompleteProps.bookYear.loading ? <InlineSpinner /> : <BuildingIcon />}
          onBlur={() => handleBlur('bookYearId')}
        />
      </FormField>
      <FormField label={t('contract')} error={getError('contractId')} required>
        <Autocomplete
          {...autocompleteProps.contract}
          startDecorator={
            autocompleteProps.contract.loading ? <InlineSpinner /> : <FileSignatureIcon />
          }
          onBlur={() => handleBlur('contractId')}
        />
      </FormField>
      <FormField label={t('performance')} error={getError('contractPerformanceId')} required>
        <Autocomplete
          {...autocompleteProps.contractPerformance}
          startDecorator={
            autocompleteProps.contractPerformance.loading ? <InlineSpinner /> : <CashRegisterIcon />
          }
          onBlur={() => handleBlur('contractPerformanceId')}
        />
      </FormField>
      <div className="flex gap-1">
        <FormField className="flex-grow" label={t('amount')} error={getError('amount')} required>
          <Input
            value={values.amount}
            onChange={(e) => setFieldValue('amount', parseFloat(e.target.value))}
            slotProps={{
              input: {
                component: NumericFormatAdapter,
              },
            }}
            onBlur={() => handleBlur('amount')}
          />
        </FormField>
        <FormField
          className="w-1/6"
          label={t('_performanceType.columns.unity')}
          error={getError('unity')}
          required
        >
          <Autocomplete {...autocompleteProps.unit} onBlur={() => handleBlur('unity')} />
        </FormField>
        <FormField className="w-1/6" label={t('unitPrice')} error={getError('unitPrice')} required>
          <Input
            value={values.unitPrice || 0}
            onChange={(e) => setFieldValue('unitPrice', parseFloat(e.target.value))}
            slotProps={{
              input: {
                component: CurrencyFormatAdapter,
              },
            }}
            onBlur={() => handleBlur('unitPrice')}
          />
        </FormField>
        <FormField className="w-1/6" label={t('total')}>
          <Input
            value={values.total || 0}
            readOnly
            slotProps={{
              input: {
                component: CurrencyFormatAdapter,
              },
            }}
          />
        </FormField>
        <FormField className="w-1/6" label={t('vat%')} error={getError('vat')} required>
          <Autocomplete {...autocompleteProps.vat} onBlur={() => handleBlur('vat')} />
        </FormField>

        <FormikDatePicker className="w-1/6" label={t('date')} required name="date" />
      </div>
      <FormField label={t('description')} error={getError('description')} required>
        <Input
          value={values.description || ''}
          onChange={(e) => setFieldValue('description', e.target.value)}
          onBlur={() => handleBlur('description')}
        />
      </FormField>
      <FormField
        label={t('_generalLedgerAccount.title')}
        error={getError('homeownerAssociationGeneralLedgerAccountId')}
      >
        <Autocomplete
          {...autocompleteProps.hoaAccount}
          startDecorator={
            autocompleteProps.hoaAccount.loading ? <InlineSpinner /> : <ReceiptIcon />
          }
          onBlur={() => handleBlur('homeownerAssociationGeneralLedgerAccountId')}
        />
      </FormField>
      <div className="flex items-end gap-2">
        <FormField className="flex-grow" label={t('divisionKey')} error={getError('divisionKeyId')}>
          <Autocomplete
            {...autocompleteProps.hoaDivisionKey}
            disabled={autocompleteProps.hoaDivisionKey.disabled || isIndividualDivisionValid}
            startDecorator={
              autocompleteProps.hoaDivisionKey.loading ? <InlineSpinner /> : <DivisionKeyIcon />
            }
            onBlur={() => handleBlur('divisionKeyId')}
          />
        </FormField>
        <KpcTooltip title={t('individualDivision')}>
          <IconButton
            disabled={!!values.divisionKeyId}
            onClick={() => individualDivisionToggle.show()}
            color={isIndividualDivisionValid ? 'primary' : 'danger'}
          >
            <IndividualDivisionIcon />
          </IconButton>
        </KpcTooltip>
      </div>

      {showIndividualDivisionWarning && (
        <span className="text-sm text-red-500">
          {t('individualAmountsByAmountMustEqualLineError')}
        </span>
      )}

      {isEdit && (
        <FormField label={t('status')} error={getError('status')} required>
          <Autocomplete {...autocompleteProps.status} onBlur={() => handleBlur('status')} />
        </FormField>
      )}

      <div className="flex justify-end gap-2 pb-4 pt-2">
        <Button
          disabled={isMutating}
          size="sm"
          color="neutral"
          variant="outlined"
          onClick={toggle.hide}
        >
          <CancelIcon className="pr-2" /> {t('buttons.cancel')}
        </Button>
        <Button
          type="submit"
          disabled={isMutating}
          startDecorator={isMutating ? <InlineSpinner /> : <ValidIcon />}
        >
          {t('buttons.save')}
        </Button>
      </div>
      {individualDivisionToggle.value && (
        <IndividualDivisionModal
          toggle={individualDivisionToggle}
          homeownerAssociationId={values.homeownerAssociationId}
          bookYearId={values.bookYearId}
          sharesName="individualDivisions"
          divisionTypeName="divisionType"
          totalAmount={values.total || 0}
        />
      )}
    </div>
  );
};

export default AddEditPerformanceFormBody;
